.header-area-certificate {
  width: 100%;
  height: 100px;
  background-color: #002d62;
  padding: 10px 0px;
}

.formSection {
  margin-top: 100px;
  padding-bottom: 100px;
}

#create-box {
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
