#basic-text1:hover {
  background-color: beige;
}

#resultBox {
  padding: 20px 20px;
  margin: 25px 0px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 160px;
}

#studentName {
  /* border-left: 1px solid #f2f2f2; */
  margin-left: 10px;
  padding-left: 10px;
  color: #6f6e6e;
  margin-top: 24px;
}

#deleteItem {
  position: absolute;
  top: 34px;
  right: 34px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
}

#deleteItem:hover {
  padding: 8px;
}
