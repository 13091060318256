/*===============================
    31.DOWNLOAD css 
================================*/

.download-area {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 32%;
    background-image: url(/images/download-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media #{$laptop} {
      width: 24%;
    }
    @media #{$md} {
      width: 0%;
    }
    @media #{$xs} {
      width: 0%;
    }
  }
  & .mobile-thumb {
    @media #{$md} {
      margin-top: 30px;
    }
    @media #{$xs} {
      margin-top: 30px;
    }
  }
  & .download-content {
    @media #{$md} {
      padding-left: 0;
    }
    @media #{$xs} {
      padding-left: 0;
    }
    & > span {
      font-size: 24px;
      color: #06f;
      font-weight: 700;
      padding-bottom: 14px;
    }
    & > .title {
      font-size: 59px;
      line-height: 70px;
      padding-bottom: 37px;
      @media #{$lg} {
        font-size: 46px;
        line-height: 56px;
      }
      @media #{$xs} {
        font-size: 24px;
        line-height: 34px;
      }
      @media #{$sm} {
        font-size: 40px;
        line-height: 50px;
      }
    }
    & p {
      font-size: 18px;
      color: #5783a6;
      line-height: 28px;

      &.text {
        font-size: 14px;
        line-height: 26px;
        padding-top: 25px;
        padding-bottom: 40px;
      }
    }
    & .store-btns {
      & .store-btn {
        & a {
          border: 2px solid #def3ff;
          padding: 20px 35px 26px 75px;
          position: relative;
          @media #{$lg} {
            padding: 20px 35px 26px 60px;
          }
          @media #{$xs} {
            padding: 20px 35px 26px 50px;
          }
          & span {
            font-weight: 700;
            letter-spacing: 3px;
            font-size: 14px;
            color: #5783a6;
            text-transform: uppercase;
          }
          & .title {
            font-size: 30px;
            text-transform: capitalize;
            @media #{$lg} {
              font-size: 25px;
            }
            @media #{$xs} {
              font-size: 25px;
            }
          }
          & i {
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 38px;
            color: #06f;
            @media #{$lg} {
              left: 15px;
            }
            @media #{$xs} {
              left: 10px;
            }
          }
        }
        &.store-btn-2 {
          @media #{$xs} {
            margin-left: 0;
            margin-top: 20px;
          }
          @media #{$sm} {
            margin-left: 20px;
            margin-top: 0;
          }
          & a {
            & i {
              color: #f26001;
            }
          }
        }
      }
    }
  }
}
