/*===============================
    29.EXP css 
================================*/

.exp-area {
  background: #e5f6ff;
  padding-top: 120px;
  padding-bottom: 120px;
  & .exp-thumb {
    @media #{$xs} {
      margin-right: 0;
    }
    & img {
      width: 100%;
    }
  }
  & .exp-content-area {
    position: relative;
    & .top-content {
      & span {
        font-size: 24px;
        color: #06f;
        font-weight: 700;
        text-transform: capitalize;
        font-family: $karla;
        padding-bottom: 8px;
        @media #{$md} {
          padding-top: 30px;
        }
        @media #{$xs} {
          padding-top: 30px;
        }
      }
      & .title {
        font-size: 60px;
        @media #{$xs} {
          font-size: 36px;
        }
        @media #{$sm} {
          font-size: 60px;
        }
      }
      & p {
        font-size: 18px;
        line-height: 28px;
        padding-right: 30px;
        padding-top: 27px;
        @media #{$md} {
          padding-right: 0;
        }
        @media #{$xs} {
          padding-right: 0;
        }
      }
    }
    & .exp-mission {
      width: 310px;
      padding: 30px;
      position: relative;
      z-index: 5;
      margin-top: 35px;
      @media #{$xs} {
        width: 100%;
      }
      @media #{$sm} {
        width: 310px;
      }
      & .title {
        font-size: 24px;
        color: #fa938f;
        font-weight: 700;
        text-transform: capitalize;
      }
      & p {
        font-size: 14px;
        color: #5783a6;
        padding-top: 15px;
      }
      & i {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 80px;
        color: #e3f7fe;
        z-index: -1;
      }
    }
    & .exp-item {
      height: 140px;
      width: 140px;
      z-index: 2;
      position: absolute;
      right: 0;
      bottom: 0;
      padding-top: 20px;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #6452db;
        z-index: -1;
      }
      &::after {
        position: absolute;
        content: "";
        left: -20px;
        top: -20px;
        width: 0;
        height: 0;
        border-top: 140px solid #24ffcd;
        border-right: 140px solid transparent;
        z-index: -2;
      }
      & span {
        color: $white;
        font-size: 80px;
        line-height: 70px;
      }
      & p {
        font-size: 14px;
        color: $white;
      }
    }
  }
}
