/*===========================
    23.BLOG css 
===========================*/

.blog-standard-area {
  & p {
    color: #333 !important;
  }

  & h2 {
    color: #333 !important;
  }

  & .blog-sidebar {
    @media #{$lg} {
      margin-left: 0;
    }

    @media #{$md} {
      margin-left: 0;
    }

    @media #{$xs} {
      margin-left: 0;
    }

    & .blog-side-about {
      padding: 37px 40px 40px;

      @media #{$lg} {
        padding: 37px 30px 40px;
      }

      @media #{$md} {
        padding: 37px 25px 40px;
      }

      @media #{$xs} {
        padding: 37px 10px 40px;
      }

      @media #{$sm} {
        padding: 37px 25px 40px;
      }

      & .about-title {
        & .title {
          font-size: 20px;
          padding-left: 46px;
          position: relative;

          &::before {
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 4px;
            width: 18px;
            background: #39bdb2;
          }

          &::after {
            position: absolute;
            content: "";
            left: 22px;
            top: 50%;
            transform: translateY(-50%);
            height: 4px;
            width: 4px;
            background: #39bdb2;
          }
        }
      }

      & .blog-about-content {
        padding-top: 33px;

        & .title {
          font-size: 18px;
          padding-top: 25px;
        }

        & p {
          color: #7886a0;
          font-size: 14px;
          padding: 20px 14px 20px;

          @media #{$xs} {
            font-size: 13px;
          }

          @media #{$sm} {
            font-size: 14px;
          }
        }

        & ul {
          & li {
            display: inline-block;

            & a {
              color: #b3bed3;
              font-size: 14px;
              margin: 0 5px;
              @include transition(0.3s);

              &:hover {
                color: #06f;
              }
            }
          }
        }
      }

      & .blog-Search-content {
        & .input-box {
          margin-top: 38px;
          position: relative;

          & input {
            width: 100%;
            border: 0;
            height: 60px;
            line-height: 60px;
            background: #f6f4ff;
            padding-left: 30px;
            color: #7e9cac;
            font-size: 14px;

            @media #{$lg} {
              padding-left: 15px;
            }

            &::placeholder {
              color: #7e9cac;
              opacity: 1;
            }
          }

          & button {
            position: absolute;
            top: 0;
            height: 100%;
            background: #06f;
            color: $white;
            border: 0;
            line-height: 60px;
            font-size: 14px;
            padding: 0 23px;
          }
        }
      }

      & .blog-feeds-content {
        & .single-feeds {
          align-items: center;
          border-bottom: 1px solid #e1e6ff;
          padding-bottom: 30px;
          padding-top: 30px;

          & .feeds-thumb {
            width: 65%;
            margin: 0 5%;

            & img {
              width: 100%;
            }
          }

          & .feeds-content {
            & .title {
              font-size: 14px;
              line-height: 22px;
            }

            & span {
              font-size: 12px;
              color: #7886a0;
              padding-top: 10px;

              & i {
                padding-right: 5px;
              }
            }
          }

          &.item {
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }

      & .blog-categories-content {
        text-align: left;

        & ul {
          & li {
            & a {
              display: block;
              background: #152136;
              color: $white;
              margin-top: 10px;
              padding-left: 30px;
              line-height: 50px;
              position: relative;

              & span {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                padding: 0 16px;
                background: #091222;
                font-weight: 700;
              }
            }
          }
        }
      }

      & .blog-social-content {
        & ul {
          & li {
            display: inline-block;

            & a {
              height: 40px;
              width: 40px;
              text-align: center;
              color: $white;
              line-height: 40px;
              border: 0;
              background: #091222;
              font-size: 14px;
              margin: 0 5px;

              @media #{$lg} {
                margin: 0 1px;
              }

              @media #{$xs} {
                margin: 0 1px;
              }
            }
          }
        }
      }

      & .blog-instagram-content {
        & .instagram-list {
          & .instagram-item {
            width: 100%;
            margin-top: 15px;

            & img {
              width: 85%;
            }
          }
        }
      }

      & .blog-tags-content {
        & ul {
          padding-top: 10px;
          display: flex;
          justify-content: space-between;

          @media #{$xs} {
            justify-content: space-evenly;
          }

          & li {
            & a {
              font-size: 12px;
              color: #5b7d9a;
              background: #f5f2ff;
              line-height: 30px;
              padding: 0 23px;
              border: 0;
              text-transform: uppercase;

              @media #{$lg} {
                padding: 0 15px;
              }

              @media #{$xs} {
                padding: 0 20px;
              }

              @media #{$sm} {
                padding: 0 23px;
              }
            }
          }
        }
      }
    }
  }

  & .blog-standard {
    & .single-blog-standard {
      overflow: hidden;
      &:hover {
        & .blog-thumb {
          overflow: hidden;
          & img {
            transform: scale(1.1);
            transition: all linear 0.4s;
          }
        }
      }
      & .blog-thumb {
        position: relative;

        & a {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 80px;
          width: 80px;
          box-shadow: 50%;
          text-align: center;
          line-height: 80px;
          border-radius: 50%;
          color: $white;
          background: #ff4a57;
        }

        & img {
          width: 100%;
          @include transition(0.3s);
        }
      }

      & .blog-content {
        padding: 24px;
        overflow: hidden;

        @media #{$lg} {
          padding: 50px 35px;
        }

        @media #{$xs} {
          padding: 15px;
        }

        @media #{$sm} {
          padding: 20px;
        }

        & > span {
          color: $white;
          background: #06f;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 30px;
          padding: 0 16px;
          letter-spacing: 3px;
          margin-bottom: 25px;
        }

        & > a {
          margin-bottom: 18px;

          & .title {
            font-size: 32px;
            line-height: 42px;

            @media #{$lg} {
              font-size: 25px;
              line-height: 45px;
            }

            @media #{$md} {
              font-size: 28px;
              line-height: 38px;
            }

            @media #{$xs} {
              font-size: 19px;
              line-height: 29px;
            }

            @media #{$sm} {
              font-size: 23px;
              line-height: 33px;
            }
          }
        }

        & ul {
          & li {
            display: inline-block;
            color: #7886a0;
            font-size: 14px;
            margin-right: 25px;

            & i {
              padding-right: 7px;
            }
          }
        }

        & p {
          padding-top: 15px;

          @media #{$xs} {
            font-size: 13px;
          }

          @media #{$sm} {
            font-size: 14px;
          }
        }

        & .user-blog-info {
          & .info {
            & img {
              margin-right: 15px;
            }

            & span {
              color: #7886a0;
              font-weight: 700;

              & span {
                color: $heading-color;
              }
            }
          }

          & .blog-details-more {
            & a {
              font-weight: 700;
              font-size: 14px;
              color: #ff4b57;
              text-transform: uppercase;
              letter-spacing: 3px;

              @media #{$xs} {
                margin-top: 20px;
              }

              @media #{$sm} {
                margin-top: 0px;
              }
            }
          }
        }
      }
    }

    & nav {
      text-align: center;
      margin-top: 60px;

      & ul {
        display: inline-block;

        & li {
          display: inline-block;

          & a {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border: 0 !important;
            padding: 0;
            line-height: 50px;
            margin: 0 3px;
            font-size: 14px;
            color: #7886a0;
            font-weight: 700;
            @include transition(0.3s);

            &:hover {
              color: $white;
              background: #ff7575;
            }

            &.active {
              color: $white;
              background: #ff7575;
            }
          }

          &:first-child {
            & a {
              border-radius: 50% !important;
            }
          }

          &:last-child {
            & a {
              border-radius: 50% !important;
            }
          }
        }
      }
    }

    & .single-blog-grid {
      background: $white;
      & .blog-thumb {
        z-index: 2;
        position: relative;
        & img {
          width: 100%;
        }
      }

      & .blog-content {
        padding: 0px 40px 35px;
        margin-top: -12px;
        z-index: 3;
        position: relative;
        @media #{$lg} {
          padding: 0 20px 35px;
        }
        @media #{$md} {
          padding: 0 20px 35px;
        }
        @media #{$xs} {
          padding: 0 15px 35px;
        }
        @media #{$sm} {
          padding: 0 40px 35px;
        }

        & span {
          color: $white;
          background: #06f;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 30px;
          padding: 0 16px;
          letter-spacing: 3px;
          margin-bottom: 25px;
        }
        & .title {
          font-size: 23px;
          line-height: 34px;
          @media #{$lg} {
            font-size: 18px;
            line-height: 28px;
          }
          @media #{$md} {
            font-size: 21px;
            line-height: 31px;
          }
          @media #{$xs} {
            font-size: 18px;
            line-height: 28px;
          }
          @media #{$sm} {
            font-size: 23px;
            line-height: 34px;
          }
        }
        & ul {
          padding-top: 10px;
          padding-bottom: 16px;
          & li {
            display: inline-block;
            color: #7886a0;
            font-size: 14px;
            margin-right: 22px;
            @media #{$lg} {
              font-size: 13px;
              margin-right: 10px;
            }
            @media #{$xs} {
              font-size: 13px;
              margin-right: 10px;
            }

            & i {
              padding-right: 7px;
            }
          }
        }
        & p {
          @media #{$xs} {
            font-size: 14px;
          }
          @media #{$sm} {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.banner-add {
  & img {
    width: 100%;
  }
}

.blog-dteails-content {
  padding: 50px;
  @media #{$xs} {
    padding: 50px 20px;
  }
  @media #{$sm} {
    padding: 50px;
  }
  & .blog-details-top {
    & > span {
      background: #30bcff;
      color: $white;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 3px;
      padding: 0 15px;
      line-height: 30px;
      text-transform: uppercase;
    }
    & .title {
      font-size: 32px;
      line-height: 42px;
      padding-top: 25px;
      @media #{$lg} {
        font-size: 25px;
        line-height: 45px;
      }
      @media #{$md} {
        font-size: 28px;
      }
      @media #{$xs} {
        font-size: 18px;
        line-height: 28px;
      }
      @media #{$sm} {
        font-size: 20px;
        line-height: 32px;
      }
    }
    & ul {
      padding: 20px 0;
      & li {
        display: inline-block;
        color: $body-text;
        font-size: 14px;
        @media #{$xs} {
          margin-right: 00px;
          margin-bottom: 10px;
        }
        @media #{$sm} {
          margin-right: 20px;
          margin-bottom: 0;
        }
        & i {
          padding-right: 6px;
        }
      }
    }
    & > p {
      color: $body-text;
      font-size: 15px;
    }
  }
  & .blog-details-bath {
    & h3.author {
      margin-top: 20px;
      color: #444444;
    }
    & img {
      width: 100%;
    }

    & .title {
      font-size: 30px;
      padding-top: 40px;
      padding-bottom: 20px;
      font-weight: 600;
      @media #{$xs} {
        font-size: 26px;
      }
      @media #{$sm} {
        font-size: 22px;
      }
    }
    & p {
      padding-bottom: 4px;
    }
    & ul {
      padding-left: 30px;
      @media #{$lg} {
        padding-left: 18px;
      }
      @media #{$xs} {
        padding-left: 0px;
      }
      @media #{$sm} {
        padding-left: 30px;
      }
      & li {
        color: $body-text;
        line-height: 30px;
        & i {
          color: #83d179;
        }
        @media #{$xs} {
          font-size: 13px;
        }
        @media #{$sm} {
          font-size: 15px;
        }
      }
    }
  }
  & .blog-details-quote {
    background: #f3f5ff;
    border-radius: 10px;
    padding: 50px 55px 57px;
    position: relative;
    z-index: 5;
    @media #{$xs} {
      padding: 50px 10px 57px;
    }
    @media #{$sm} {
      padding: 50px 50px 57px;
    }
    & p {
      color: $heading-color;
      font-weight: 700;
      & span {
        color: $body-text;
      }
    }
    & .title {
      line-height: 38px;
      padding-top: 13px;
      @media #{$lg} {
        font-size: 20px;
      }
      @media #{$xs} {
        font-size: 17px;
        line-height: 27px;
      }
      @media #{$sm} {
        font-size: 19px;
        line-height: 29px;
      }
    }
    & i {
      font-size: 190px;
      color: #e4e9ff;
      z-index: -1;
      position: absolute;
      bottom: 15px;
      right: 30px;
      @media #{$xs} {
        font-size: 90px;
      }
    }
  }
  & .blog-details-confarance {
    & .confarance-flex {
      @media #{$xs} {
        display: block !important;
      }
      & .confarance-thumb-1 {
        width: 50%;
        @media #{$xs} {
          width: 100%;
        }
        & img {
          width: 100%;
          padding-top: 10px;
        }
      }
      & .confarance-thumb-2 {
        width: 83%;
        padding-left: 35px;
        @media #{$xs} {
          padding-left: 0;
          width: 100%;
          padding-top: 20px;
        }
        & p {
        }
      }
    }
  }
  & .blog-details-bar {
    @media #{$xs} {
      display: block !important;
    }
    @media #{$sm} {
      display: flex !important;
    }
    & .blog-tags {
      .title {
        font-size: 20px;
        text-transform: capitalize;
        padding: 0 5%;
      }
      & ul {
        & li {
          display: inline-block;
          & a {
            background: #f5f2ff;
            color: $heading-color;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 30px;
            padding: 0 20px;
            margin-right: 7px;
            margin-top: 27px;
            @include transition(0.3s);
            @media #{$xs} {
              margin-right: 2px;
              padding: 0 15px;
            }
            @media #{$sm} {
              margin-right: 0;
              padding: 0 10px;
            }

            &:hover {
              background: #ff4b57;
              color: $white;
            }
          }
        }
      }
    }
    & .blog-social {
      @media #{$xs} {
        text-align: left !important;
      }
      @media #{$sm} {
        text-align: right !important;
      }
      & .title {
        font-size: 20px;
        text-transform: capitalize;
        padding-bottom: 20px;
        @media #{$xs} {
          margin-top: 20px;
        }
        @media #{$sm} {
          margin-top: 0px;
        }
      }
      & ul {
        & li {
          display: inline-block;
          & a {
            color: #b5becc;
            font-size: 18px;
            margin-left: 28px;
            @media #{$xs} {
              margin-left: 0;
              margin-right: 20px;
            }
            @media #{$sm} {
              margin-left: 20px;
              margin-right: 0px;
            }
            @include transition(0.3s);
            @media #{$sm} {
              margin-left: 15px;
            }
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
  & .blog-details-next-prev {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    position: relative;
    & .post-prev {
      & a {
        & span {
          color: $body-text;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        & .title {
          font-size: 28px;
          text-transform: capitalize;
          padding-top: 5px;
          @media #{$lg} {
            font-size: 20px;
          }
          @media #{$md} {
            font-size: 24px;
          }
          @media #{$xs} {
            font-size: 18px;
          }
          @media #{$sm} {
            font-size: 18px;
          }
        }
      }
    }
    & .post-next {
      & a {
        & span {
          color: $body-text;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        & .title {
          font-size: 28px;
          text-transform: capitalize;
          padding-top: 5px;
          @media #{$lg} {
            font-size: 20px;
          }
          @media #{$md} {
            font-size: 24px;
          }
          @media #{$xs} {
            font-size: 18px;
          }
          @media #{$sm} {
            font-size: 18px;
          }
        }
      }
    }
    & > a {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media #{$xs} {
        display: none;
      }
      @media #{$sm} {
        display: block;
      }
    }
  }
  & .blog-details-releted-post {
    & .title {
      font-size: 26px;
      padding-bottom: 5px;
      font-weight: 600;
    }
    & .blog-details-releted-item {
      box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
      & .releted-thumb {
        & img {
          width: 100%;
        }
      }
      & .releted-content {
        padding: 35px 40px;
        @media #{$lg} {
          padding: 35px 20px;
        }
        @media #{$md} {
          padding: 35px 20px;
        }
        @media #{$xs} {
          padding: 35px 15px;
        }
        @media #{$sm} {
          padding: 35px 40px;
        }
        & span {
          font-size: 14px;
          color: $body-text;
          padding-bottom: 5px;
          & i {
            padding-right: 5px;
          }
        }
        & .title {
          font-size: 20px;
          line-height: 30px;
          @media #{$lg} {
            font-size: 16px;
          }
          @media #{$xs} {
            font-size: 17px;
          }
          @media #{$sm} {
            font-size: 20px;
          }
        }
        & p {
          font-size: 14px;
          line-height: 26px;
          padding-top: 3px;
        }
      }
    }
  }
  & .blog-details-written {
    border: 2px solid #ddd;
    padding: 40px;
    @media #{$xs} {
      padding: 15px;
    }
    @media #{$sm} {
      padding: 31px;
    }

    & .written-area {
      @media #{$xs} {
        display: block !important;
      }
      & .written-thumb {
        width: 100%;
        & img {
          width: 100%;
        }
      }
      & .written-content {
        padding-left: 40px;
        @media #{$xs} {
          padding-left: 0;
        }
        & span {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 2px;
          color: $body-text;
          @media #{$xs} {
            padding-top: 20px;
          }
        }
        & .title {
          font-size: 36px;
          @media #{$lg} {
            font-size: 30px;
          }
          @media #{$xs} {
            font-size: 22px;
          }
          @media #{$sm} {
            font-size: 30px;
          }
        }
        & p {
          font-size: 14px;
          color: $body-text;
          line-height: 26px;
          padding-top: 7px;
        }
      }
    }
  }
  & .blog-details-comments {
    position: relative;
    & > .title {
      font-size: 26px;
      padding-bottom: 5px;
    }

    & .blog-comments-area {
      margin-top: 40px;
      padding-left: 130px;
      position: relative;
      border-bottom: 1px solid #ddd;
      padding-bottom: 25px;

      @media #{$xs} {
        padding-left: 0px;
      }
      @media #{$sm} {
        padding-left: 110px;
      }
      & .blog-left {
        & .title {
          font-size: 18px;
        }
        & span {
          font-size: 12px;
          color: $theme-color;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 2px;
          padding-top: 5px;
          padding-bottom: 15px;
        }
      }
      & .blog-right {
        & a {
          color: #69a1bb;
          font-weight: 700;
          font-size: 14px;
          line-height: 40px;
          border: 2px solid #ddd;
          padding: 0 20px;
          border-radius: 30px;
        }
      }
      & p {
        font-size: 14px;
      }
      & img {
        position: absolute;
        left: 0;
        top: -10px;
        @media #{$xs} {
          width: 18%;
          position: inherit;
          top: 0;
          padding: 15px 0;
        }
        @media #{$sm} {
          width: 18%;
          position: absolute;
          top: -10px;
          padding: 0;
        }
      }
      &.ml-125 {
        @media #{$xs} {
          margin-left: 50px;
        }
      }
      & .d-flex {
        @media #{$xs} {
          display: block !important;
        }
        @media #{$sm} {
          display: flex !important;
        }
      }
    }
  }
  & .blog-details-post-comments {
    margin-top: 40px;
    & .title {
      font-size: 26px;
      padding-bottom: 30px;
    }
    & .post-comments-area {
      padding: 30px 50px 50px;
      @media #{$sm} {
        padding: 30px 50px 50px;
      }
      @media #{$xs} {
        padding: 20px;
      }
      & .input-box {
        & textarea {
          width: 100%;
          border: 0;
          height: 150px;
          resize: none;
          padding-top: 20px;
          padding-left: 30px;
          &::placeholder {
            opacity: 1;
            font-size: 14px;
            color: #6b93aa;
          }
        }
        & input {
          width: 100%;
          border: 0;
          line-height: 60px;
          padding-left: 30px;
          &::placeholder {
            opacity: 1;
            color: #6b93aa;
            font-size: 14px;
          }
        }
        & button {
          background: #ff4b57;
          line-height: 60px;
          padding: 0 60px;
          border-color: #ff4b57;
          border-radius: 30px;
          text-transform: capitalize;
          font-size: 14px;
          color: $white;
          font-weight: 700;
          @media #{$xs} {
            line-height: 50px;
            padding: 0 30px;
          }
          & i {
            padding-right: 6px;
          }
        }
      }
    }
  }
}

.news-3-area {
  padding-top: 120px;
  padding-bottom: 120px;
  & .section-title {
    & > span {
      width: 0;
      height: 0;
      border-top: 18px solid #0066ff;
      border-right: 18px solid transparent;
    }

    & .title {
      color: $heading-color;
      padding-top: 10px;
      padding-bottom: 33px;
    }
  }
  & .single-news {
    & .news-thumb {
      & img {
        width: 100%;
      }
    }
    & .news-content {
      border: 2px solid #daecff;
      border-top: 0;
      padding: 35px;
      @media #{$lg} {
        padding: 20px;
      }
      @media #{$xs} {
        padding: 10px;
      }
      @media #{$sm} {
        padding: 35px;
      }
      & ul {
        li {
          display: inline-block;
          font-size: 14px;
          color: #5783a6;
          margin-right: 18px;
          & i {
            padding-right: 5px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      & .title {
        font-size: 22px;
        line-height: 32px;
        padding-top: 5px;
        padding-bottom: 20px;
        @media #{$lg} {
          font-size: 20px;
        }
      }
      & .btns {
        & span {
          color: $heading-color;
          background: #24ffcd;
          padding: 0 20px;
          line-height: 30px;
          border-radius: 30px;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        & a {
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          border: 1px solid #dbecff;
          font-size: 14px;
          color: $heading-color;
        }
      }
    }
  }
}
