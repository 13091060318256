/*===========================
    17.SPONSORS css 
===========================*/

.sponsors-area {
  position: relative;
  padding-top: 104px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: -moz-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    opacity: 0.8;
  }
  & .section-title {
    & .title {
      color: #001b61;
      margin-bottom: 12px;
      font-size: 44px;
    }
  }
}

.overley {
  opacity: 0.8;
  position: absolute;
  left: 0px;
  top: 4255px;
  width: 1920px;
  height: 698px;
  z-index: 63;
}
.owl-carousel .owl-item img {
  display: block;
  width: 65%;
}
