/*===========================
    3.DREAM COURSE css 
===========================*/

.dream-course-area {
  margin-top: -136px;
  padding-bottom: 110px;
  position: relative;
  z-index: 6;

  & .dream-course-content {
    background: $white;
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px rgba(189, 201, 255, 0.2);
    padding: 70px 80px 80px;

    @media #{$xs} {
      padding: 10px;
      & div {
        margin: 12px 0px;
      }
    }

    @media #{$sm} {
      padding: 70px 80px 80px;
    }

    & .dream-course-title {
      & span {
        font-size: 20px;
        font-weight: 700;
        color: #06f;
        padding-bottom: 20px;
      }
    }

    & .dream-course-search {
      border-radius: 50px;
      border: 2px solid #ebecf6;
      height: 80px;
      align-items: center;
      padding: 0 2%;
      @media #{$xs} {
        height: auto;
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
      }
      @media #{$sm} {
        height: 80px;
        padding: 0px 50px;
      }

      & .input-box {
        position: relative;

        & i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: #f16001;
          @media #{$xs} {
            left: -30px;
            top: 27%;
          }
          @media #{$sm} {
            left: -25px;
            top: 50%;
          }
        }

        & input {
          width: 260px;
          border: 0;
          padding: 0px 20%;
          color: #a6b2cf;
          @media #{$lg} {
            width: 190px;
          }
          @media #{$md} {
            width: 290px;
          }
          @media #{$xs} {
            width: 100%;
            padding-left: 0px;
            padding-bottom: 30px;
          }
          @media #{$sm} {
            width: 170px;
            padding-left: 10px;
            padding-bottom: 0;
          }

          &::placeholder {
            color: #a6b2cf;
            opacity: 1;
          }
        }
      }
      & .dream-course-category {
        width: 25%;

        @media #{$xs} {
          width: 100%;
        }
        @media #{$sm} {
          width: 100%;
        }

        & .nice-select {
          width: 25%;
          border: 0;
          border-radius: 0;
          border-left: 2px solid #d3dbef;
          margin-right: 25px;
          padding: 0px 30%;
          @media #{$xs} {
            border: none;
          }
          @media #{$sm} {
            border: none;
          }

          @media #{$lg} {
            width: 145px;
          }
        }
      }
      & .dream-course-btn {
        & .findLink {
          background: transparent;
          border: 2px solid #2d589a;
          color: #2d589a;
          text-align: center;
          white-space: nowrap;
          border-radius: 30px;
          padding: 0 43px;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          height: 48px;
          display: flex;
          align-items: center;
          // box-shadow: 0px 8px 16px 0px rgba(48, 188, 255, 0.2);

          @media #{$sm} {
            padding: 0 10px;
          }
        }
      }
    }
  }
}
