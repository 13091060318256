/*===============================
    30.OUR COURSES css 
================================*/

.our-courses-area {
  background: #f5fbff;
  padding-top: 120px;
  padding-bottom: 120px;
  & .section-title {
    & > span {
      width: 0;
      height: 0;
      border-top: 18px solid #0066ff;
      border-right: 18px solid transparent;
    }

    & .title {
      color: $heading-color;
      padding-top: 10px;
      padding-bottom: 58px;
    }
    & .nav {
      border-bottom: 2px solid #d6e7ff;
      & li {
        & a {
          font-size: 16px;
          font-weight: 700;
          color: #5783a6;
          padding: 0 35px 15px;
          border-radius: 0;
          margin-bottom: -2px;
          @media #{$lg} {
            padding: 0 22px 15px;
          }
          @media #{$md} {
            padding: 0 5px 15px;
            font-size: 13px;
          }
          @media #{$xs} {
            padding: 0 0px 15px;
            font-size: 16px;
          }
          @media #{$sm} {
            padding: 0 0px 15px;
            font-size: 11px;
          }
          &.active {
            color: $heading-color;
            background-color: transparent;
            border-bottom: 3px solid #fa938f;
          }
        }
      }
    }
  }
  & .single-courses-3 {
    & .courses-thumb {
      position: relative;
      & img {
        width: 100%;
      }
      & .courses-overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: -moz-linear-gradient(
          90deg,
          rgba(0, 27, 94, 0.50196) 0%,
          rgba(0, 27, 94, 0) 100%
        );
        background-image: -webkit-linear-gradient(
          90deg,
          rgba(0, 27, 94, 0.50196) 0%,
          rgba(0, 27, 94, 0) 100%
        );
        background-image: -ms-linear-gradient(
          90deg,
          rgba(0, 27, 94, 0.50196) 0%,
          rgba(0, 27, 94, 0) 100%
        );
        background-image: -o-linear-gradient(
          90deg,
          rgba(0, 27, 94, 0.50196) 0%,
          rgba(0, 27, 94, 0) 100%
        );
        & ul {
          position: absolute;
          left: 30px;
          bottom: 30px;
          & li {
            display: inline-block;
            & i {
              color: #ffc600;
            }
            & span {
              font-size: 14px;
              color: $white;
              padding-left: 5px;
            }
            & p {
              color: $heading-color;
              background: #24ffcd;
              border-radius: 30px;
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
              line-height: 30px;
              padding: 0 20px;
              margin-left: 17px;
            }
          }
        }
      }
    }
    & .courses-content {
      background: $white;
      padding: 35px;
      @media #{$lg} {
        padding: 20px;
      }
      @media #{$md} {
        padding: 20px;
      }
      @media #{$xs} {
        padding: 12px;
      }
      @media #{$sm} {
        padding: 35px;
      }
      & .title {
        font-size: 22px;
        line-height: 32px;
        @media #{$lg} {
          font-size: 20px;
        }
        @media #{$xs} {
          font-size: 18px;
        }
        @media #{$sm} {
          font-size: 22px;
        }
      }
      & p {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 25px;
      }
      & ul {
        border-top: 1px solid #cbeaff;
        padding-top: 4px;
        & li {
          display: inline-block;
          font-size: 14px;
          color: #5783a6;
          & i {
            padding-right: 5px;
          }
          & p {
            font-weight: 700;
            padding-left: 28px;
            @media #{$lg} {
              padding-left: 15px;
            }
            @media #{$xs} {
              padding-left: 20px;
            }
            & span {
              font-weight: 400;
              color: #f26001;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  & .courses-active-3 {
    & .slick-arrow {
      position: absolute;
      top: -195px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      text-align: center;
      line-height: 60px;
      color: #87a1c1;
      cursor: pointer;
      @include transition(0.3s);
      background: $white;
      z-index: 9;
      &::before {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 2px solid #e4f5ff;
      }

      @media #{$lg} {
        top: -150px;
      }

      @media #{$md} {
        top: -150px;
      }

      &:hover {
        background: $theme-color;
        color: $white;
        border-color: $theme-color;
        box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
      }

      &.prev {
        right: 125px;
      }

      &.next {
        right: 15px;
      }
    }
  }
}
