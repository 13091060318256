// Font Family

$montserrat: "Montserrat", sans-serif;

$karla: "Karla", sans-serif;

$philosopher: "Philosopher", sans-serif;

// Colors ---------------

$white: #fff;

$black: #222;

$grey: #f7f7fd;

$theme-color: #fff;

$body-text: #6b93aa;

$hr-border: #eceff8;

// Heading Color
$heading-color: #001b61;

$headerBackgroundColor: #002d62;

// Soft color
$black-soft: #7e7e7e;

// Responsive Variables
$laptop: "only screen and (min-width: 1200px) and (max-width: 1600px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1200px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";
