/*===========================
    18.COURSE css 
===========================*/

.course-grid-area {
  background: #f7fcff;

  & .course-sidebar {
    & .course-price-filter {
      padding: 30px 10px 20px;

      & .course-title {
        border-bottom: 1px solid #e1f2ff;
        padding-bottom: 15px;
        margin-bottom: 20px;

        & .title {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    & .course-add {
      position: relative;

      & img {
        width: 100%;
      }

      & .item-2 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
      }

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: -moz-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        background-image: -webkit-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        background-image: -ms-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        opacity: 0.8;
      }
    }
  }

  & .course-grid {
    & .course-grid-top {
      & .course-info {
        & ul {
          & li {
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            color: $heading-color;
            margin-right: 40px;

            @media #{$lg} {
              margin-right: 0;
              font-size: 16px;
            }

            & i {
              color: #2079ff;
              margin-right: 10px;
            }

            &:last-child {
              margin-right: 0;

              & i {
                color: #f16001;
              }
            }
          }
        }
      }

      & .course-filter {
        & .nice-select {
          background: $white;
          border: 0;
          height: 45px;
          line-height: 45px;
          border-radius: 30px;
          text-align: center;
          padding: 0 50px 0 35px;

          @media #{$xs} {
            margin-top: 20px;
            margin-right: 10px;
          }

          @media #{$sm} {
            margin-top: 0px;
            margin-right: 0;
          }

          & span {
            color: $heading-color;
            font-size: 14px;
            font-weight: 700;
          }

          &::after {
            right: 25px;
            border-bottom: 2px solid $heading-color;
            border-right: 2px solid $heading-color;
          }
        }

        & .input-box {
          position: relative;

          & i {
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
            left: 54px;
            font-size: 14px;
            color: #ff9595;

            @media #{$xs} {
              display: none;
            }
          }

          & input {
            height: 45px;
            line-height: 45;
            border: 0;
            background: $white;
            border-radius: 30px;
            margin-left: 30px;
            padding-left: 50px;
            width: 190px;
            font-size: 14px;
            color: #9aa7ca;

            @media #{$lg} {
              width: 160px;
            }

            @media #{$xs} {
              width: 160px;
              padding-left: 15px;
              margin-left: 0px;
              margin-top: 20px;
            }

            @media #{$sm} {
              width: 150px;
              padding-left: 15px;
              margin-left: 10px;
              margin-top: 0;
            }

            &::placeholder {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  & .single-courses {
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    margin-bottom: 0px;

    & .courses-thumb {
      position: relative;
      overflow: hidden;
      z-index: 5;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: -moz-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-image: -webkit-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-image: -ms-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-image: -o-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: -1;
      }

      & img {
        width: 100%;
        height: 200px;
        transform: scale(1);
        @include transition(0.3s);
        z-index: -2;
      }

      & .courses-review {
        position: absolute;
        left: 30px;
        bottom: 20px;
        z-index: 1;

        & span {
          font-size: 14px;
          color: #999;

          & span {
            color: $white;
            font-weight: 700;
          }

          & i {
            color: #ffea00;
          }
        }
      }

      & .corses-thumb-title {
        position: absolute;
        bottom: 0;

        &.item-2 {
          & span {
            color: $theme-color;
          }
        }

        &.item-3 {
          & span {
            color: #2687ff;
          }
        }

        & span {
          background: $white;
          color: #ff9595;
          font-size: 14px;
          font-weight: 700;
          text-transform: capitalize;
          height: 36px;
          line-height: 36px;
          padding: 0;
          padding: 0 23px;
          border-radius: 5px 5px 0 0;
        }
      }
    }

    & .courses-content {
      padding: 16px 26px;
      background: $white;

      @media #{$lg} {
        padding: 19px 10px;
      }

      @media #{$xs} {
        padding: 20px;
      }

      @media #{$sm} {
        padding: 37px;
      }

      & a {
        min-height: 85px;
      }
      & .title {
        line-height: 30px;
        font-size: 16px;
        font-weight: 600;
        @include transition(0.3s);

        @media #{$lg} {
          font-size: 16px;
        }

        @media #{$md} {
          font-size: 17px;
        }

        @media #{$xs} {
          font-size: 13px;
        }

        @media #{$sm} {
          font-size: 14px;
        }
      }

      & .courses-info {
        align-items: center;
        // padding-top: 20px;

        & .item {
          position: relative;

          & img {
            position: absolute;
            left: 0;
          }

          & p {
            font-weight: 700;
            color: #2079ff;
            font-size: 14px;
            padding-left: 0px;
          }
        }

        & span {
          color: #53cfa5;
          background: #e6fff2;
          border-radius: 5px;
          height: 35px;
          line-height: 45px;
          padding: 0 15px;
          font-weight: 700;
          @include transition(0.3s);
        }
      }

      & ul {
        border-top: 1px solid #e2f4ff;
        margin-top: 25px;
        padding-top: 20px;

        & li {
          display: inline-block;
          font-size: 14px;
          color: #99b7d1;
          margin-right: 15px;

          @media #{$lg} {
            margin-right: 10px;
            font-size: 13px;
          }

          @media #{$md} {
            margin-right: 12px;
            font-size: 13px;
          }

          @media #{$xs} {
            margin-right: 12px;
            font-size: 12px;
          }

          & i {
            padding-right: 5px;
          }
        }
      }
    }

    &:hover {
      & .courses-content {
        & .title {
          color: "#002d62";
        }

        & .courses-info {
          & span {
            background: #53cfa5;
            color: $white;
          }
        }
      }

      & .courses-thumb {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }

  & .pagination-item {
    direction: ltr;
    & nav {
      & ul {
        & li {
          & a {
            height: 50px;
            width: 50px;
            border-radius: 50% !important;
            border: 0;
            padding: 0;
            text-align: center;
            line-height: 50px;
            margin: 0 6px;
            font-size: 14px;
            color: #70a3bb;
            font-weight: 700;
            @include transition(0.3s);

            @media #{$xs} {
              height: 30px;
              width: 30px;
              line-height: 30px;
              font-size: 13px;
            }

            @media #{$sm} {
              height: 45px;
              width: 45px;
              line-height: 45px;
              font-size: 13px;
            }

            & span {
              color: $heading-color;
            }

            &.active {
              background: #00ffd8;
              color: $heading-color;
            }

            &:hover {
              background: #00ffd8;
              color: $heading-color;
            }
          }
        }
      }
    }
  }

  & .single-course-list {
    @media #{$xs} {
      display: block;
    }

    & .course-list-thumb {
      width: 50%;

      @media #{$xs} {
        width: 100%;
      }

      & img {
        width: 100%;
      }
    }

    & .courses-content {
      width: 50%;
      padding: 40px;
      background: $white;

      @media #{$lg} {
        padding: 24px;
      }

      @media #{$xs} {
        padding: 20px;
        width: 100%;
      }

      @media #{$sm} {
        padding: 37px;
      }

      & .title {
        line-height: 30px;
        font-size: 20px;
        @include transition(0.3s);
        padding-bottom: 12px;

        @media #{$lg} {
          font-size: 17px;
        }

        @media #{$md} {
          font-size: 17px;
        }

        @media #{$xs} {
          font-size: 15px;
          line-height: 25px;
        }

        @media #{$sm} {
          font-size: 24px;
        }
      }

      & p {
        font-size: 14px;
        line-height: 26px;
        color: #7da8be;
      }

      & .courses-info {
        align-items: center;
        // padding-top: 20px;

        & .item {
          position: relative;

          & img {
            position: absolute;
            left: 0;
          }

          & p {
            font-weight: 700;
            color: #2079ff;
            font-size: 14px;
            padding-left: 40px;
          }
        }

        & span {
          color: #53cfa5;
          background: #e6fff2;
          border-radius: 5px;
          height: 35px;
          line-height: 45px;
          padding: 0 15px;
          font-weight: 700;
          @include transition(0.3s);
        }
      }

      & ul {
        border-top: 1px solid #e2f4ff;
        margin-top: 25px;
        padding-top: 20px;

        & li {
          display: inline-block;
          font-size: 14px;
          color: #99b7d1;
          margin-right: 15px;

          @media #{$lg} {
            margin-right: 10px;
            font-size: 13px;
          }

          @media #{$md} {
            margin-right: 12px;
            font-size: 13px;
          }

          @media #{$xs} {
            margin-right: 12px;
            font-size: 12px;
          }

          & i {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

/*------------------radio style common-----------------*/

.radio_common-2 li {
  display: block;
  margin-right: 0;
}

.radio_common-2 li label {
  color: $heading-color;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  width: 100%;
}

.radio_common-2 li label b {
  position: absolute;
  right: 0;
  font-weight: 400;
}

.radio_common-2 li input[type="radio"] {
  display: none;
}

.radio_common-2 li input[type="radio"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.radio_common-2.square li input[type="radio"] + label span {
  border-radius: 3px;
}

.radio_common-2 li input[type="radio"] + label span {
  border: 2px solid #e6e8f4;
  position: relative;
}

.radio_common-2 li input[type="radio"] + label span::before {
  background: #fff;
  border-radius: 100%;
  content: "";
  position: absolute;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.radio_common-2.square li input[type="radio"] + label span::before {
  border-radius: 0px;
}

.radio_common-2 li input[type="radio"]:checked + label span {
  border-color: #2079ff;
  background: #2079ff;
}

.radio_common-2 li input[type="radio"]:checked + label {
  color: #2079ff;
}

.radio_common-2 li input[type="radio"]:checked + label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.radio_common-2 li input[type="radio"] + label span,
.radio_common-2 li input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.radio_style2 li input[type="radio"] + label span:before {
  height: 6px;
  left: 5px;
  top: 5px;
  width: 6px;
}

.course-title-area {
  position: relative;
  margin-top: 80px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 27, 97, 0.902);
  }

  & .course-title-content {
    & .course-title-content-title {
      & span {
        color: #efff83;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
        padding-bottom: 13px;
      }

      & .title {
        color: $white;
        font-size: 50px;
        line-height: 60px;
        font-weight: 600;
        padding-bottom: 10px;
        @media #{$lg} {
          font-size: 40px;
          line-height: 50px;
        }
        @media #{$md} {
          font-size: 44px;
          line-height: 54px;
        }
        @media #{$xs} {
          font-size: 28px;
          line-height: 38px;
        }
        @media #{$sm} {
          font-size: 34px;
          line-height: 44px;
        }
      }

      & p {
        font-size: 20px;
        line-height: 30px;
        color: $white;
        opacity: 0.7;
        padding-right: 90px;
        padding-bottom: 25px;
        @media #{$lg} {
          font-size: 18px;
        }
        @media #{$xs} {
          font-size: 14px;
          padding-right: 0;
        }
        @media #{$sm} {
          font-size: 16px;
          padding-right: 0;
        }
      }
    }

    & .course-rating {
      align-items: center;
      @media #{$xs} {
        display: block !important;
      }

      & > span {
        background: #ff4f74;
        color: $white;
        height: 30px;
        line-height: 30px;
        padding: 0 28px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 30px 30px 30px 0;
        margin-right: 20px;
        margin-right: 8px;
      }

      & ul {
        @media #{$xs} {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        & li {
          display: inline-block;

          & i {
            color: #fff000;
            margin-right: 5px;
          }

          & span {
            font-size: 14px;
            color: $white;

            & i {
              color: #00fffc;
              font-size: 16px;
            }
          }
        }
      }

      & p {
        font-size: 14px;
        color: $white;
        padding: 0 30px 0 10px;
        @media #{$lg} {
          padding: 0 10px 0 5px;
        }
      }
    }

    & .course-info {
      padding-top: 15px;

      & ul {
        & li {
          display: inline-block;
          color: $white;
          font-size: 14px;
          margin-right: 30px;

          & i {
            color: #e400ff;
            margin-right: 5px;
          }

          &:last-child {
            & i {
              color: #ffa358;
            }
          }
        }
      }
    }
  }
}

.course-details-area {
  background: #f7fcff;
  padding-top: 120px;
  padding-bottom: 120px;

  & .course-details-items {
    padding: 40px;
    @media #{$xs} {
      padding: 10px;
    }
    @media #{$sm} {
      padding: 40px;
    }

    & .course-thumb {
      & img {
        width: 100%;
      }

      & .tab-btns {
        border-bottom: 2px solid #e8f2ff;
        padding-top: 26px;

        & ul {
          & li {
            & a {
              font-size: 18px;
              color: #6b93aa;
              font-weight: 700;
              line-height: 60px;
              padding: 0 25px;
              @media #{$lg} {
                font-size: 15px;
                padding: 0 20px;
              }
              @media #{$md} {
                font-size: 16px;
              }
              @media #{$xs} {
                font-size: 12px;
                padding: 0 0;
              }
              @media #{$sm} {
                font-size: 14px;
                padding: 0 0;
              }

              & i {
                padding-right: 5px;
                color: #30e578;
              }

              &.active {
                background-color: transparent;
                color: #30bcff;
                position: relative;

                & i {
                  color: #30bcff !important;
                }

                &::before {
                  position: absolute;
                  content: "";
                  left: 0;
                  bottom: -2px;
                  width: 100%;
                  height: 2px;
                  background: #30bcff;
                }
              }
            }

            &:nth-child(2) {
              & a {
                & i {
                  color: #f16001;
                }
              }
            }

            &:nth-child(3) {
              & a {
                & i {
                  color: #ff4f74;
                }
              }
            }

            &:nth-child(4) {
              & a {
                & i {
                  color: #0066ff;
                }
              }
            }
          }
        }
      }
    }

    & .course-details-item {
      & .course-text {
        & p {
          font-size: 14px;
          color: #6b93aa;
          line-height: 26px;
          padding-right: 15px;
          padding-top: 20px;
          @media #{$xs} {
            margin-right: 0;
          }
        }
      }
    }

    & .course-learn-list {
      .title {
        font-size: 20px;
        padding-top: 30px;
        padding-bottom: 15px;
      }

      & .course-learn-item {
        position: relative;
        padding-left: 20px;
        margin-top: 8px;

        & i {
          position: absolute;
          left: 0;
          top: 6px;
          color: #f16001;
          font-size: 14px;
        }

        & p {
          font-size: 14px;
          color: #6b93aa;
          line-height: 26px;
        }
      }
    }

    & .course-learn-text {
      padding-bottom: 40px;

      & .title {
        font-size: 20px;
        padding-top: 28px;
        padding-bottom: 20px;
      }

      & span {
        font-size: 14px;
        color: #06f;
        font-weight: 700;
        padding-bottom: 15px;
      }

      & p {
        font-size: 14px;
      }
    }

    & .course-learner-slide {
      margin-bottom: 35px;

      & .course-learner-item {
        padding: 25px 20px;
        background: #eff7ff;
        @media #{$xs} {
          display: block !important;
        }
        @media #{$sm} {
          display: flex !important;
        }

        & .course-learner-thumb {
          width: 40%;
          @media #{$xs} {
            width: 100%;
          }
          @media #{$sm} {
            width: 80%;
          }

          & img {
            width: 100%;
          }
        }

        & .course-learner-content {
          padding-left: 30px;
          @media #{$xs} {
            padding-left: 0;
            padding-top: 20px;
          }
          @media #{$sm} {
            padding-left: 30px;
            padding-top: 0;
          }

          & .title {
            font-size: 18px;
          }

          & span {
            font-size: 14px;
            color: #30bcff;
            font-weight: 700;
            letter-spacing: 3px;
            text-transform: uppercase;
            padding-bottom: 10px;
          }

          & p {
            font-size: 14px;
            color: #6b93aa;
            padding-right: 20px;
          }
        }
      }

      & .slick-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;

        & li {
          display: inline-block;

          & button {
            height: 4px;
            width: 10px;
            border-radius: 10px;
            background: #dfebff;
            font-size: 0;
            border: 0;
            margin: 0 4px;
          }

          &.slick-active {
            & button {
              width: 20px;
              background: #ff4f74;
            }
          }
        }
      }
    }
  }

  & .course-details-sidebar {
    margin-top: -450px;
    @media #{$md} {
      margin-top: 40px;
    }
    @media #{$xs} {
      margin-top: 40px;
    }

    & .course-sidebar-thumb {
      position: relative;
      padding: 10px;

      & a {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 80px;
        width: 80px;
        text-align: center;
        line-height: 80px;
        border-radius: 50%;
        color: $white;
        background: #06f;
      }

      & img {
        width: 100%;
      }
    }

    & .course-sidebar-price {
      padding: 15px 40px 0;
      @media #{$xs} {
        padding: 15px 10px 0;
      }
      @media #{$sm} {
        padding: 15px 40px 0;
      }

      & .title {
        font-size: 34px;
        color: #ff9595;
        @media #{$lg} {
          font-size: 24px;
        }
        @media #{$xs} {
          font-size: 24px;
        }
        @media #{$sm} {
          font-size: 34px;
        }

        & > span {
          font-size: 18px;
          color: #a1adcf;
          font-weight: 400;
          text-decoration: line-through;
          @media #{$lg} {
            font-size: 14px;
          }
        }
      }

      & > span {
        font-size: 18px;
        font-weight: 700;
        color: $heading-color;
        @media #{$lg} {
          font-size: 14px;
        }
      }
    }

    & .course-sidebar-btns {
      padding: 10px 40px 40px;
      @media #{$xs} {
        padding: 10px 10px 0;
      }
      @media #{$sm} {
        padding: 10px 40px 0;
      }

      & p {
        color: #06f;
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 25px;

        & i {
          padding: 0px 10px;
        }
      }

      & a {
        width: 100%;
        height: 60px;
        line-height: 56px;
        background: #30e578;
        color: $white;
        text-align: center;
        border-radius: 30px;
        margin-bottom: 20px;
        border: 2px solid #30e578;
        font-size: 14px;
        font-weight: 700;
        @include transition(0.3s);

        & i {
          padding-right: 5px;
        }

        &:hover {
          background-color: transparent;
          border-color: #ebecf6;
          color: #a8b3d2;
          font-weight: 700;
        }

        &.btns {
          background-color: transparent;
          border-color: #ebecf6;
          color: #a8b3d2;
          &:hover {
            background: #30e578;
            border-color: #30e578;
            color: $white;
          }
        }
      }
      & .title {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 10px;
      }
      & ul {
        & li {
          font-size: 14px;
          color: #6b93aa;
          line-height: 30px;

          & i {
            padding-right: 5px;
          }
        }
      }
    }
    & .course-sidebar-share {
      padding-top: 35px;
      & a {
        width: 100%;
        text-align: center;
        height: 60px;
        line-height: 60px;
        color: $white;
        font-size: 14px;
        font-weight: 700;
        background: #30bcff;
        & i {
          padding-right: 5px;
        }
      }
    }
  }
  & .trending-course {
    & > .title {
      font-size: 22px;
      padding-top: 35px;
      & i {
        color: #ff3074;
        margin-right: 8px;
      }
    }
  }
}

.tab-content {
  & .tab-pane {
    display: block;
    overflow: hidden;
    height: 0;
    visibility: hidden;
    max-width: 100%;
    opacity: 0;

    &.active {
      height: auto;
      visibility: visible;
      opacity: 1;
      overflow: visible;
    }
  }
}
