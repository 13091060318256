/*===========================
    20.GALLERY css 
===========================*/

.gallery-area {
  & .gallery-item {
    position: relative;
    overflow: hidden;

    & img {
      width: 100%;
    }

    & .gallery-overlay {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(2);
      height: 89%;
      width: 89%;
      background-image: -webkit-linear-gradient(
        0deg,
        rgba(36, 0, 255, 0.7) 0%,
        rgba(0, 144, 255, 0.7) 100%
      );
      background-image: -moz-linear-gradient(
        0deg,
        rgba(36, 0, 255, 0.7) 0%,
        rgba(0, 144, 255, 0.7) 100%
      );
      background-image: -ms-linear-gradient(
        0deg,
        rgba(36, 0, 255, 0.7) 0%,
        rgba(0, 144, 255, 0.7) 100%
      );
      background-image: -o-linear-gradient(
        0deg,
        rgba(36, 0, 255, 0.7) 0%,
        rgba(0, 144, 255, 0.7) 100%
      );
      opacity: 0;
      @include transition(0.3s);

      & .gallery-content {
        & a {
          color: $white;
          font-size: 50px;
          @media #{$lg} {
            font-size: 36px;
          }
          @media #{$md} {
            font-size: 28px;
          }
          @media #{$xs} {
            font-size: 30px;
          }
        }

        & .title {
          color: $white;
          font-size: 50px;
          @media #{$laptop} {
            font-size: 44px;
          }
          @media #{$lg} {
            font-size: 36px;
          }
          @media #{$md} {
            font-size: 30px;
          }
          @media #{$xs} {
            font-size: 20px;
          }
        }
        &.gallery-content-2 {
          & .title {
            font-size: 30px;
            @media #{$laptop} {
              font-size: 24px;
            }
            @media #{$lg} {
              font-size: 20px;
            }
            @media #{$md} {
              font-size: 30px;
            }
            @media #{$xs} {
              font-size: 20px;
            }
          }
        }
      }
    }

    &:hover {
      & .gallery-overlay {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}
