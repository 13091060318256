/*===========================
    15.ACCESS css 
===========================*/

.access-area {
  & .access-thumb {
    & img {
      width: 100%;
    }
  }

  & .access-content {
    @media #{$md} {
      margin-top: 40px;
    }
    @media #{$xs} {
      margin-top: 40px;
    }

    & p {
      color: rgba(13, 23, 48, 0.64);
    }
    & .access-title {
      & > span {
        width: 0;
        height: 0;
        border-bottom: 18px solid #0066ff;
        border-left: 18px solid transparent;
      }

      & .title {
        font-size: 50px;
        line-height: 60px;
        padding-bottom: 30px;
        padding-top: 24px;
        @media #{$lg} {
          font-size: 42px;
          line-height: 52px;
        }
        @media #{$xs} {
          font-size: 30px;
          line-height: 40px;
        }
        @media #{$sm} {
          font-size: 46px;
          line-height: 56px;
        }

        & span {
          color: #ff9595;
          display: inline;
        }
      }
    }

    & .access-mission {
      border-bottom: 1px solid #dbf1ff;
      padding-bottom: 31px;
      margin-bottom: 31px;

      & .title {
        font-size: 24px;
        padding-bottom: 12px;
        color: rgba(13, 23, 48, 0.87);

        & span {
          color: #2687ff;
        }
      }

      & p {
        font-size: 16px;
        line-height: 28px;
        color: rgba(13, 23, 48, 0.64);

        @media #{$xs} {
          font-size: 14px;
          padding-right: 0;
        }
      }
    }

    & .access-vision {
      & .title {
        font-size: 24px;
        padding-bottom: 12px;

        & span {
          color: #ff79bb;
        }
      }

      & p {
        font-size: 16px;
        line-height: 28px;
        color: #6b93aa;
        padding-right: 40px;
        @media #{$xs} {
          font-size: 14px;
          padding-right: 0;
        }
      }
    }
  }
}
