/*===========================
    10.COMMUNITY css 
===========================*/

.community-area {
  padding-top: 104px;
  padding-bottom: 120px;
  position: relative;

  &::before {
    // position: absolute;
    // content: "";
    // right: 0;
    // width: 30%;
    // top: 0;
    // height: 100%;
    // background-image: url(/../images/pexels-photo-1078850.jpeg);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;

    @media #{$md} {
      width: 0;
    }

    @media #{$xs} {
      width: 0;
    }
  }

  & .community-content {
    & .title {
      font-size: 56px;
      line-height: 72px;
      color: rgba(13, 23, 48, 0.87);

      @media #{$lg} {
        font-size: 50px;
        // line-height: 60px;
      }

      @media #{$md} {
        font-size: 46px;
        // line-height: 56px;
        padding-bottom: 30px;
      }

      @media #{$xs} {
        font-size: 26px;
        // line-height: 36px;
        padding-bottom: 30px;
      }

      @media #{$sm} {
        font-size: 36px;
        line-height: 46px;
        padding-bottom: 30px;
      }

      & span {
        color: #53cfa5;
      }
    }

    & p {
      font-size: 18px;
      line-height: 32px;
      color: rgba(13, 23, 48, 0.64);

      @media #{$xs} {
        font-size: 16px;
      }
    }

    & .input-box {
      margin-top: 35px;
      position: relative;

      @media #{$xs} {
        display: flex;
        flex-direction: column;
        min-height: 200px;
      }

      @media #{$sm} {
        display: flex;
        flex-direction: column;
        min-height: 200px;
      }

      & input {
        width: 100%;
        line-height: 120px;
        height: 120px;
        border: 20px solid #f2faff;
        border-radius: 80px;
        padding-left: 40px;
        color: #6b93aa;

        @media #{$xs} {
          height: 90px;
          line-height: 90px;
          padding-left: 15px;
        }

        &::placeholder {
          opacity: 1;
          color: #6b93aa;
        }
      }

      & button {
        position: absolute;
        right: 33px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 60px;
        height: 60px;
        padding: 0 50px;
        background: #8976ff;
        color: $white;
        border-radius: 40px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        box-shadow: 0px 8px 16px 0px rgba(137, 118, 255, 0.2);

        @media #{$xs} {
          display: inherit;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 140px;
        }

        @media #{$sm} {
          display: inline-block;
          left: auto;
          right: 33px;
          transform: translateY(-50%);
          top: 50%;
        }
      }
    }
  }

  & .community-thumb {
    position: absolute;
    left: 1160px;
    top: 50%;
    transform: translateY(-50%);

    @media #{$laptop} {
      left: 800px;
    }

    @media #{$lg} {
      left: 600px;
    }

    & img {
      width: 90%;
    }
  }
}
