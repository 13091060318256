/*===========================
    24.CONTACT css 
===========================*/

.contact-info-area {
  padding-top: 106px;
  padding-bottom: 84px;

  & .section-title {
    & .title {
      & span {
        color: #bb51ff;
      }
    }

    & p {
      padding: 0 0px 75px 0;
    }
  }

  & .contact-info-content {
    & .single-contact-info {
      margin-bottom: 30px;

      & .info-icon {
        margin-right: 30px;

        & i {
          height: 80px;
          width: 80px;
          text-align: center;
          line-height: 80px;
          color: $white;
          border-radius: 50%;
          background: #ff5e79;
          font-size: 24px;
          box-shadow: 0px 8px 16px 0px rgba(255, 94, 121, 0.3);
        }
      }

      & .info-contact {
        margin: 0px 5%;
        & .title {
          font-size: 24px;
          padding-bottom: 6px;

          @media #{$xs} {
            font-size: 20px;
          }
        }

        & span {
          font-size: 18px;

          @media #{$xs} {
            font-size: 16px;
          }
        }
      }

      &.item-2 {
        & .info-icon {
          & i {
            background: #30bcff;
            box-shadow: 0px 8px 16px 0px rgba(48, 188, 255, 0.3);
          }
        }
      }

      &.item-3 {
        & .info-icon {
          & i {
            background: #ffb62a;
            box-shadow: 0px 8px 16px 0px rgba(255, 182, 42, 0.3);
          }
        }
      }
    }
  }

  & .contact-info-thumb {
    direction: ltr;
    @media #{$md} {
      margin-left: 0;
    }

    @media #{$xs} {
      margin-left: 0;
    }

    & .item-2 {
      margin-top: -185px;
      margin-left: 115px;
      border: 15px solid #fff;

      @media #{$lg} {
        margin-left: 40px;
      }

      @media #{$md} {
        margin-left: 40px;
      }

      @media #{$xs} {
        margin-top: 15px;
        margin-left: 0;
        border: 0;
      }

      @media #{$sm} {
        margin-top: -185px;
        margin-left: 115px;
        border: 15px solid #fff;
      }
    }
  }
}

.contact-action-area {
  padding-bottom: 116px;
  padding-top: 106px;
  position: relative;

  & .contact-action-item {
    & .title {
      font-size: 70px;
      padding-bottom: 42px;

      @media #{$md} {
        font-size: 46px;
      }

      @media #{$xs} {
        font-size: 30px;
      }

      @media #{$sm} {
        font-size: 36px;
      }
    }

    & .input-box {
      position: relative;

      & input {
        width: 100%;
        border: 2px solid #ddecf3;
        height: 70px;
        line-height: 70px;
        padding-left: 40px;
        color: #6b93aa;
        padding-right: 60px;

        &::placeholder {
          opacity: 1;
          color: #6b93aa;
        }
      }

      & i {
        position: absolute;
        right: 40px;
        top: 25px;
        color: #6b93aa;
      }

      & textarea {
        width: 100%;
        height: 170px;
        border: 2px solid #ddecf3;
        padding-left: 40px;
        color: #6b93aa;
        padding-top: 20px;
        padding-right: 60px;
        resize: none;

        &::placeholder {
          opacity: 1;
          color: #6b93aa;
        }
      }
      & button {
        background: #06f;
        color: $white;
        line-height: 70px;
        padding: 0 60px;
        border: 0;
        border-radius: 40px;
        text-transform: capitalize;
        font-weight: 700;
        margin-top: 30px;
      }
    }
  }

  & .map {
    position: absolute;
    top: 0;
    height: 100%;
    width: 47%;

    @media #{$md} {
      width: 0;
    }

    @media #{$xs} {
      width: 0;
    }

    & iframe {
      height: 100%;
      width: 100%;
    }
  }
}
