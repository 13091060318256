/*===========================
    14.MENTORS css 
===========================*/

.mentors-area {
  position: relative;
  z-index: 5;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 26%;
    height: 100%;
    background-image: url(/images/mentors-bg-1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;

    @media #{$laptop} {
      width: 15%;
    }

    @media #{$lg} {
      width: 15%;
    }

    @media #{$md} {
      width: 0;
    }

    @media #{$xs} {
      width: 0;
    }
  }

  &::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    width: 510px;
    height: 510px;
    background-image: url(/images/mentors-bg-2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;

    @media #{$laptop} {
      height: 300px;
      width: 300px;
    }

    @media #{$md} {
      height: 0px;
      width: 0px;
    }

    @media #{$xs} {
      height: 0px;
      width: 0px;
    }
  }

  & .section-title {
    & .title {
      & span {
        color: $theme-color;
      }
    }
  }

  & .single-mentors {
    & .mentors-thumb {
      overflow: hidden;

      & img {
        width: 100%;
        transform: scale(1);
        @include transition(0.3s);
      }
    }

    & .mentors-content {
      padding: 32px 40px 35px;

      @media #{$lg} {
        padding: 32px 20px 35px;
      }

      @media #{$md} {
        padding: 32px 20px 35px;
      }

      @media #{$xs} {
        padding: 32px 20px 35px;
      }

      @media #{$sm} {
        padding: 32px 40px 35px;
      }

      & .title {
        font-size: 33px;

        @media #{$lg} {
          font-size: 26px;
        }

        @media #{$xs} {
          font-size: 24px;
        }

        @media #{$sm} {
          font-size: 26px;
        }
      }

      & span {
        color: #2079ff;
        font-weight: 700;
        padding-top: 5px;
      }

      & p {
        color: #6b93aa;
        line-height: 28px;
        padding-top: 20px;
        margin-top: 22px;
        border-top: 1px solid #e1ebff;
      }
    }

    &:hover {
      & .mentors-thumb {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.mentor-details-area {
  & .mentor-thumb {
    & img {
      width: 100%;
    }
  }

  & .mentor-content {
    padding-left: 40px;

    @media #{$md} {
      padding-left: 0;
    }

    @media #{$xs} {
      padding-left: 0;
    }

    & .mentor-title {
      & span {
        width: 0;
        height: 0;
        border-bottom: 18px solid #0066ff;
        border-left: 18px solid transparent;

        @media #{$md} {
          margin-top: 30px;
        }

        @media #{$xs} {
          margin-top: 30px;
        }
      }

      & .title {
        font-size: 50px;
        padding-top: 15px;
        padding-bottom: 10px;

        @media #{$lg} {
          font-size: 46px;
        }

        @media #{$xs} {
          font-size: 27px;
        }

        @media #{$sm} {
          font-size: 50px;
        }
      }

      & p {
        text-transform: uppercase;
        color: #30bcff;
        font-weight: 700;
        letter-spacing: 3px;
        padding-bottom: 31px;

        @media #{$xs} {
          font-size: 14px;
        }
      }
    }

    & .mentor-item {
      & span {
        font-size: 24px;
        line-height: 34px;

        @media #{$lg} {
          font-size: 19px;
        }

        @media #{$xs} {
          font-size: 16px;
          line-height: 26px;
        }

        @media #{$sm} {
          font-size: 20px;
          line-height: 30px;
        }
      }

      & p {
        line-height: 28px;
        padding-top: 15px;
        padding-bottom: 25px;

        @media #{$xs} {
          font-size: 14px;
        }

        @media #{$sm} {
          font-size: 16px;
        }
      }

      & ul {
        & li {
          display: inline-block;

          & a {
            height: 70px;
            width: 70px;
            text-align: center;
            line-height: 70px;
            border-radius: 50%;
            border: 2px solid #6cdabd;
            color: #6b93aa;
            font-size: 18px;
            @include transition(0.3s);
            margin-right: 14px;

            @media #{$xs} {
              height: 40px;
              width: 40px;
              margin-right: 12px;
              line-height: 36px;
              font-size: 13px;
            }

            &:hover {
              background: #6cdabd;
              border-color: #6cdabd;
              color: $white;
            }
          }
        }
      }
    }
  }
}
