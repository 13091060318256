/*===========================
    4.SERVICES css 
===========================*/

.services-area {
  @media #{$sm} {
    margin-top: 110px;
  }

  @media #{$xs} {
    margin-top: 110px;
  }

  & .services-border {
    border: 1px solid #ebecf6;
    cursor: pointer;
    & .single-services {
      border: 1px solid #ebecf6;
      padding: 80px 0 78px;
      position: relative;
      @include transition(0.3s);

      & i {
        font-size: 80px;
        color: #022d62;
        @include transition(0.3s);
      }

      & .title {
        font-size: 16px;
        color: $heading-color;
        padding-top: 27px;
        @include transition(0.3s);
      }
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transform: scale(1);
        @include transition(0.3s);
        background: $white;
      }
      &:hover {
        z-index: 10;
        &::before {
          transform: scale(1.15);
          background: #1e549f;
          cursor: pointer;
        }
        & i {
          color: $white;
        }
        & .title {
          color: $white;
        }
      }
      &.item-2 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
      &.item-3 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
      &.item-4 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
      &.item-5 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
      &.item-6 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
      &.item-7 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
      &.item-8 {
        & i {
          color: #022d62;
        }
        &:hover {
          & i {
            color: $white;
          }
        }
      }
    }
  }
}

.services-area-3 {
  padding-top: 90px;
  padding-bottom: 120px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #001b61;
  }
  & .single-services {
    border: 2px solid rgba(255, 255, 255, 0.302);
    padding-top: 60px;
    padding-bottom: 55px;
    & i {
      font-size: 30px;
      color: #6452db;
      height: 100px;
      width: 100px;
      text-align: center;
      line-height: 100px;
      border-radius: 50%;
      background: $white;
    }
    & span {
      display: block;
      font-size: 20px;
      color: $white;
      font-weight: 700;
      text-transform: capitalize;
      padding-top: 25px;
    }
    &.item-2 {
      & i {
        color: #06f;
      }
    }
    &.item-3 {
      & i {
        color: #ff6767;
      }
    }
    &.item-4 {
      & i {
        color: #1de8ba;
      }
    }
    &.item-5 {
      & i {
        color: #ffbe40;
      }
    }
    &.item-6 {
      & i {
        color: #7ba457;
      }
    }
    &.item-7 {
      & i {
        color: #ff554d;
      }
    }
    &.item-8 {
      & i {
        color: #0056a6;
      }
    }
  }
}
