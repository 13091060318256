/*===========================
    1. COMMON css 
===========================*/

@import url("https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i|Montserrat:300,300i,400,400i,500,500i,600,600i,700,900|Philosopher:400,700,700i&display=swap");

body {
  font-family: $karla;
  font-weight: normal;
  font-style: normal;
  color: $body-text;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $montserrat;
  font-weight: 700;
  color: $heading-color;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $body-text;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

// others common css here

/*===== All Button Style =====*/

.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  @include user-select(none);
  border: 1px solid $theme-color;
  padding: 0 43px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  color: $white;
  cursor: pointer;
  z-index: 5;
  @include transition(0.4s);
  background-color: $theme-color;

  &:hover {
    background-color: $white;
    color: $theme-color;
    border-color: $theme-color;
  }

  &.main-btn-2 {
    background-color: $white;
    color: $theme-color;
    border-color: $theme-color;

    &:hover {
      background-color: $theme-color;
      border-color: $theme-color;
      color: $white;
    }
  }
}

.section-title {
  & .title {
    font-size: 70px;
    color: $heading-color;
    line-height: 80px;
    padding-bottom: 65px;

    @media #{$lg} {
      font-size: 60px;
      line-height: 70px;
    }

    @media #{$md} {
      font-size: 46px;
      line-height: 56px;
    }

    @media #{$xs} {
      font-size: 30px;
      line-height: 40px;
    }

    @media #{$sm} {
      font-size: 36px;
      line-height: 46px;
    }

    & span {
      color: rgba(13, 23, 48, 0.87);
      display: block;
    }
  }

  &.section-title-2 {
    & .title {
      padding-bottom: 7px;

      & span {
        color: #bb51ff;
        display: inline;
      }
    }

    & p {
      font-size: 20px;
      line-height: 30px;
      color: #6b93aa;
      padding: 0 60px 46px;

      @media #{$xs} {
        padding: 0 0px 46px;
        font-size: 16px;
      }

      @media #{$sm} {
        padding: 0 0px 46px;
        font-size: 18px;
      }
    }
  }
}

.error-area {
  height: 100vh;
  position: relative;

  & .error-thumb {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;

    & img {
      width: 100%;
    }
  }

  & .error-content {
    & > span {
      font-size: 30px;
      color: #06f;
      padding-bottom: 15px;

      @media #{$md} {
        font-size: 22px;
      }

      @media #{$xs} {
        font-size: 18px;
      }
    }

    & .title {
      font-size: 80px;
      line-height: 90px;
      padding-bottom: 35px;

      @media #{$laptop} {
        font-size: 70px;
        line-height: 80px;
      }

      @media #{$lg} {
        font-size: 65px;
        line-height: 75px;
      }

      @media #{$md} {
        font-size: 44px;
        line-height: 54px;
      }

      @media #{$xs} {
        font-size: 30px;
        line-height: 40px;
      }
    }

    & ul {
      & li {
        display: inline-block;

        & a {
          background: #24ffce;
          color: $heading-color;
          line-height: 60px;
          padding: 0 40px;
          border-radius: 30px;
          font-weight: 700;
          border: 2px solid #24ffce;
          margin-right: 18px;
          @include transition(0.3s);

          @media #{$xs} {
            line-height: 50px;
            padding: 0 20px;
            font-size: 14px;
            margin-bottom: 30px;
          }

          &:hover {
            border-color: #daebff;
            color: #739fba;
            background-color: transparent;
          }
        }

        &:last-child {
          & a {
            background-color: transparent;
            color: #739fba;
            border-color: #daebff;

            &:hover {
              border-color: #24ffce;
              background: #24ffce;
              color: $heading-color;
            }
          }
        }
      }
    }
  }
}

/*===== All Preloader Style =====*/
#preloader {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

#status {
  left: 50%;
  margin: -25px 0 0 -15px;
  position: fixed;
  top: 50%;
}

#status,
.spinner {
  height: 30px;
  width: 50px;
}

.spinner {
  font-size: 10px;
  margin: 0 auto;
  text-align: center;
}

.spinner > div {
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  -moz-animation: stretchdelay 1.2s infinite ease-in-out;
  -o-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
  background-color: #30bcff;
  display: inline-block;
  height: 100%;
  width: 6px;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  -o-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  20% {
    -webkit-transform: scaleY(1.6);
    -moz-transform: scaleY(1.6);
    -ms-transform: scaleY(1.6);
    -o-transform: scaleY(1.6);
    transform: scaleY(1.6);
  }
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  20% {
    -webkit-transform: scaleY(1.6);
    -moz-transform: scaleY(1.6);
    -ms-transform: scaleY(1.6);
    -o-transform: scaleY(1.6);
    transform: scaleY(1.6);
  }
}
