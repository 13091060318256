/*===========================
    11.FOOTER css 
===========================*/

.footer-area {
  background: #001b61;
  padding-top: 113px;
  padding-bottom: 85px;

  & .footer-item {
    & .footer-title {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 25px;
      padding-left: 40px;

      &::before {
        position: absolute;
        content: "";
        left: 20px;
        bottom: 0;
        width: 250px;
        height: 1px;
        background: #fff;

        @media #{$lg} {
          width: 200px;
        }
      }

      & i {
        position: absolute;
        left: 0;
        top: 5px;
        font-size: 24px;
        color: #00d2ff;
      }

      & .title {
        color: $white;

        @media #{$lg} {
          font-size: 18px;
        }
      }

      & p {
        font-size: 14px;
        color: $white;
        opacity: 0.6;

        @media #{$lg} {
          font-size: 12px;
        }
      }

      &.item-2 {
        & i {
          color: #ff50af;
        }
      }

      &.item-3 {
        & i {
          color: #ffde00;
        }
      }
    }

    & .footer-list-area {
      & .footer-list {
        & ul {
          & li {
            & a {
              color: $white;
              opacity: 0.6;
              line-height: 46px;
              @include transition(0.3s);

              & i {
                padding-right: 22px;
              }

              &:hover {
                color: $theme-color;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    & .footer-instagram {
      & .instagram-item {
        & img {
          width: 85%;
          margin-top: 20px;
        }
      }
    }
  }

  &.footer-area-2 {
    background: #002d62;
    position: relative;
    z-index: 5;

    @media #{$sm} {
      display: inline-block;
      min-height: 1100px;
      background: #002d62;
      height: auto;
    }

    @media #{$xs} {
      display: inline-block;
      min-height: 1100px;
      background: #002d62;
      height: auto;
    }

    &::before {
      position: absolute;
      content: "";
      left: 20px;
      top: 0;
      height: 100%;
      width: 26%;
      background: #fff;
      @media #{$laptop} {
        width: 16%;
      }
      @media #{$lg} {
        width: 16%;
      }
      @media #{$md} {
        width: 0%;
      }
      @media #{$xs} {
        width: 0%;
      }
    }
    & .footer-dot {
      position: absolute;
      bottom: 40px;
      left: 40px;
    }
    & .footer-shape {
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: -1;
      text-align: right;
      & img {
        width: 100%;
        @media #{$laptop} {
          width: 50%;
        }
        @media #{$lg} {
          width: 48%;
        }
        @media #{$md} {
          width: 58%;
        }
        @media #{$sm} {
          width: 75%;
        }
      }
    }

    & .footer-item {
      & .footer-title {
        & p {
          opacity: 0.8;
        }

        &::before {
          background: #fff;
        }
      }

      & .footer-list-area {
        & .footer-list {
          & ul {
            & li {
              & a {
                opacity: 1;
              }
            }
          }
        }
      }

      & .footer-content {
        & .title {
          font-size: 38px;
          line-height: 48px;
          color: $white;
          padding-bottom: 30px;
          @media #{$xs} {
            font-size: 32px;
          }
        }

        & .input-box {
          position: relative;
          & input {
            width: 100%;
            line-height: 70px;
            height: 70px;
            border: 4px solid #003a91;
            background: #004ab8;
            font-size: 14px;
            color: #c7deff;
            padding-left: 35px;

            &::placeholder {
              opacity: 1;
              color: #c7deff;
            }
          }
          & i {
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
            color: #00fffc;
            font-size: 16px;
          }
        }
        & button {
          margin-top: 20px;
          background: #24ffce;
          padding: 0 40px;
          height: 50px;
          line-height: 56px;
          color: $heading-color;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          & i {
            padding-right: 5px;
          }
        }
      }
    }
  }
  &.mentor-footer {
    &::before {
      width: 100%;
    }
  }
}

/*===========================
    BACK TO TOP CSS
===========================*/

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: none;
  z-index: 99;

  & a {
    color: $white;
    background: $theme-color;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  }
}
