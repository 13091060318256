/*===========================
    3.BANNER css 
===========================*/

.banner-area {
  height: 900px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
    z-index: -2;
  }

  & .banner-content {
    & > span {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: $white;
      letter-spacing: 2px;
      padding-bottom: 18px;
    }

    @media #{$xs} {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 110px;
    }

    @media #{$sm} {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 110px;
    }

    & .title {
      color: $white;
      font-size: 80px;
      line-height: 90px;

      @media #{$lg} {
        font-size: 72px;
      }

      @media #{$xs} {
        font-size: 38px;
        line-height: 48px;
      }

      @media #{$sm} {
        font-size: 60px;
        line-height: 70px;
      }
    }

    & ul {
      padding-top: 22px;

      @media #{$xs} {
        display: inline;
      }

      @media #{$sm} {
        display: inline;
      }

      & li {
        margin: 0 1%;
        display: inline-block;

        & a {
          line-height: 54px;
          border-radius: 30px;
          background: #2d589a;
          font-size: 16px;
          color: $white;
          border: 2px solid #2d589a;
          margin-right: 23px;

          @media #{$xs} {
            margin-bottom: 20px;
          }

          @media #{$sm} {
            margin-bottom: 0px;
          }

          & span {
            padding-right: 8px;
          }

          &:hover {
            border-color: $headerBackgroundColor;
            background-color: transparent;
            color: $white;
          }

          &.main-btn-2 {
            background-color: transparent;
            border-color: rgba(255, 255, 255, 0.3);
            color: $white;

            &:hover {
              background: $headerBackgroundColor;
              border-color: $headerBackgroundColor;
              color: $white;
            }
          }
        }
      }
    }
  }

  & .banner-shape-1 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;

    & img {
      width: 100%;

      @media #{$laptop} {
        width: 50%;
      }

      @media #{$lg} {
        width: 50%;
      }

      @media #{$md} {
        width: 50%;
      }

      @media #{$xs} {
        width: 0%;
      }

      @media #{$sm} {
        width: 30%;
      }
    }
  }

  & .banner-shape-2 {
    position: absolute;
    bottom: -10px;
    right: 0;
    text-align: right;
    z-index: -1;

    & img {
      width: 100%;

      @media #{$laptop} {
        width: 70%;
      }

      @media #{$lg} {
        width: 80%;
      }

      @media #{$md} {
        width: 60%;
      }

      @media #{$xs} {
        width: 0%;
      }

      @media #{$sm} {
        width: 60%;
      }
    }
  }

  &.banner-area-2 {
    height: 800px;

    &::before {
      background-image: -moz-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(255, 57, 57) 100%
      );
      background-image: -webkit-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(255, 57, 57) 100%
      );
      background-image: -ms-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(255, 57, 57) 100%
      );
      opacity: 0.502;
    }

    & .banner-content {
      & a {
        height: 100px;
        width: 100px;
        text-align: center;
        line-height: 96px;
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        font-size: 24px;
        color: $white;
      }

      & span {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        color: $white;
        letter-spacing: 3px;
        padding-top: 35px;
        @media #{$xs} {
          font-size: 15px;
        }
      }

      & .title {
        font-size: 120px;
        padding-top: 20px;
        position: relative;
        @media #{$lg} {
          font-size: 90px;
        }
        @media #{$md} {
          font-size: 80px;
        }
        @media #{$xs} {
          font-size: 48px;
        }
        @media #{$sm} {
          font-size: 60px;
        }

        &::before {
          position: absolute;
          content: "";
          top: -180px;
          left: 400px;
          width: 0;
          height: 0;
          border-top: 44px solid #57beff;
          border-right: 44px solid transparent;
          @media #{$lg} {
            left: 300px;
          }
          @media #{$md} {
            left: 200px;
          }
          @media #{$xs} {
            left: 0px;
          }
          @media #{$sm} {
            left: 100px;
          }
        }

        &::after {
          position: absolute;
          content: "";
          bottom: -120px;
          right: 50px;
          width: 0;
          height: 0;
          border-top: 44px solid #fff834;
          border-right: 44px solid transparent;
        }
      }
    }
  }
  &.banner-area-3 {
    height: 800px;
    & .banner-content {
      & > span {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 2px;
        padding-bottom: 28px;
        @media #{$lg} {
          font-size: 18px;
        }
        @media #{$md} {
          font-size: 18px;
        }
        @media #{$xs} {
          font-size: 13px;
        }
        @media #{$sm} {
          font-size: 18px;
        }
      }
      & .title {
        font-size: 100px;
        padding-bottom: 10px;
        @media #{$lg} {
          font-size: 80px;
        }
        @media #{$md} {
          font-size: 80px;
        }
        @media #{$xs} {
          font-size: 50px;
          line-height: 60px;
        }
        @media #{$sm} {
          font-size: 60px;
        }
      }
      & ul {
        & li {
          & a {
            margin: 0 10px;
            @media #{$xs} {
              margin: 15px 10px;
            }
            @media #{$sm} {
              margin: 0px 10px;
            }
          }
        }
      }
    }
    & .banner-thumb {
      position: absolute;
      right: 0;
      bottom: 0;
      width: auto;
      text-align: right;
      z-index: -1;
      & img {
        width: 60%;
        @media #{$lg} {
          width: 52%;
        }
      }
    }
    & .banner-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      & img {
        width: 100%;
      }
    }
  }
}

.page-title {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 27, 97, 0.502);
  }
  & .page-title-item {
    & .title {
      font-size: 80px;
      color: $white;
      padding-bottom: 5px;
      @media #{$lg} {
        font-size: 70px;
      }
      @media #{$md} {
        font-size: 68px;
      }
      @media #{$xs} {
        font-size: 42px;
      }
    }
    & nav {
      display: inline-block;
      & ol {
        background-color: transparent;
        padding: 0;
        li {
          color: $white;
          text-transform: uppercase;
          font-weight: 700;
          & a {
            color: $white;
          }
        }
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "|";
  color: $white;
}
