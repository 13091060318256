/*===========================
    12.SUB ITEMS css 
===========================*/

.sub-items-area {
  & .sub-item {
    background: #043e44;
    height: 390px;
    padding: 80px;

    @media #{$laptop} {
      padding: 30px;
      height: 290px;
    }

    @media #{$lg} {
      padding: 20px;
      height: 320px;
    }
    @media #{$md} {
      padding: 80px;
      height: auto;
    }
    @media #{$xs} {
      padding: 10px;
      height: auto;
    }
    @media #{$sm} {
      padding: 50px;
      height: auto;
    }

    & .title {
      color: $white;
      font-size: 40px;

      @media #{$laptop} {
        font-size: 36px;
      }
      @media #{$lg} {
        font-size: 32px;
      }
      @media #{$xs} {
        font-size: 30px;
      }
    }

    & .input-box {
      position: relative;
      margin: 31px 0 40px;

      & i {
        position: absolute;
        left: 45px;
        top: 54%;
        transform: translateY(-50%);
        @media #{$laptop} {
          left: 15px;
        }
        @media #{$lg} {
          font-size: 0;
        }
        @media #{$md} {
          font-size: 0;
        }
        @media #{$xs} {
          font-size: 0;
        }
      }

      & input {
        width: 100%;
        line-height: 70px;
        height: 70px;
        border-radius: 40px;
        border: 0;
        padding-left: 75px;
        @media #{$laptop} {
          padding-left: 40px;
        }
        @media #{$lg} {
          padding-left: 10px;
        }
        @media #{$xs} {
          padding-left: 10px;
        }
      }

      & button {
        position: absolute;
        right: 0;
        top: 0;
        height: 70px;
        line-height: 70px;
        padding: 0 45px;
        border-radius: 40px;
        background: #24ffce;
        color: $heading-color;
        text-transform: capitalize;
        border: 0;
        font-weight: 700;
        @media #{$lg} {
          padding: 0 25px;
        }
        @media #{$xs} {
          padding: 0 20px;
        }
        @media #{$sm} {
          padding: 0 40px;
        }
      }
    }

    & .sub-content {
      & p {
        font-size: 18px;
        color: #acd4d8;
        line-height: 30px;
        padding-top: 20px;
        padding-bottom: 22px;
      }
    }

    &.item-2 {
      background: #14626a;
    }

    &.item-3 {
      background-image: url(/images/sub-item-bg.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      z-index: 5;

      &::before {
        position: absolute;
        content: "";
        background-color: rgba(0, 61, 68, 0.9);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
      }
    }
  }
}

/*------------------radio style common-----------------*/

.radio_common li {
  display: inline-block;
  margin-right: 35px;
}

.radio_common li label {
  color: #88b2b6;
  font-size: 18px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}

.radio_common li input[type="radio"] {
  display: none;
}

.radio_common li input[type="radio"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.radio_common.square li input[type="radio"] + label span {
  border-radius: 3px;
}

.radio_common li input[type="radio"] + label span {
  border: 2px solid #356468;
  position: relative;
}

.radio_common li input[type="radio"] + label span::before {
  background: #ff617f;
  border-radius: 100%;
  content: "";
  position: absolute;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.radio_common.square li input[type="radio"] + label span::before {
  border-radius: 0px;
}

.radio_common li input[type="radio"]:checked + label span {
  border-color: #fff;
  background: #fff;
}

.radio_common li input[type="radio"]:checked + label {
  color: #fff;
}

.radio_common li input[type="radio"]:checked + label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.radio_common li input[type="radio"] + label span,
.radio_common li input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.radio_style2 li input[type="radio"] + label span:before {
  height: 10px;
  left: 3px;
  top: 3px;
  width: 10px;
}
