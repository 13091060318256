/*===========================
    19.EVENT DESK css 
===========================*/

.event-desk-area {
  padding-top: 107px;
  background: #f6fcff;

  & .section-title {
    & p {
      padding-bottom: 34px;
    }
  }

  & .event-desk-content {
    & span {
      color: #06f;
      font-size: 26px;
      padding-bottom: 15px;

      @media #{$xs} {
        font-size: 18px;
      }

      @media #{$sm} {
        font-size: 22px;
      }
    }

    & .title {
      font-size: 68px;
      line-height: 80px;

      @media #{$lg} {
        font-size: 54px;
        line-height: 64px;
      }

      @media #{$xs} {
        font-size: 28px;
        line-height: 38px;
      }

      @media #{$sm} {
        font-size: 52px;
        line-height: 62px;
      }
    }

    & p {
      font-size: 20px;
      line-height: 32px;
      padding-right: 20px;
      padding-top: 15px;

      @media #{$lg} {
        font-size: 18px;
        padding-right: 0;
      }

      @media #{$xs} {
        font-size: 14px;
        padding-right: 0;
      }

      @media #{$sm} {
        font-size: 17px;
        padding-right: 0;
      }
    }

    & ul {
      padding-top: 44px;

      & li {
        display: inline-block;

        & a {
          line-height: 54px;
          border-radius: 30px;
          background: #24ffce;
          font-size: 14px;
          font-weight: 700;
          color: $heading-color;
          border: 2px solid #24ffce;
          margin-right: 30px;

          @media #{$xs} {
            margin: 0 10px 20px;
          }

          @media #{$sm} {
            margin: 0 10px 0px;
          }

          & i {
            padding-right: 5px;
          }

          & span {
            padding-right: 8px;
          }

          &:hover {
            border-color: #001e92;
            background-color: #001e92;
            color: $white;
          }

          &.main-btn-2 {
            border-color: #001e92;
            background-color: #001e92;
            color: $white;

            &:hover {
              background: #24ffce;
              border-color: #24ffce;
              color: $heading-color;
            }
          }
        }
      }
    }
  }

  & .desk-thumb {
    margin-left: 30px;

    @media #{$lg} {
      margin-left: 0;
    }

    @media #{$md} {
      margin-left: 0;
      margin-top: 40px;
    }

    @media #{$xs} {
      margin-left: 0;
      margin-top: 40px;
    }

    & .item-1 {
      margin-left: 100px;
      margin-top: -185px;
      border: 14px solid #f6fcff;

      @media #{$xs} {
        margin-top: 30px;
        margin-left: 0;
        border: 0;
      }

      @media #{$sm} {
        margin-left: 100px;
        margin-top: -185px;
        border: 14px solid #f6fcff;
      }
    }
  }
}

.event-contact-area {
  position: relative;
  padding-top: 104px;

  & .event-contact-item {
    & .title {
      font-size: 70px;
      padding-bottom: 41px;
      @media #{$xs} {
        font-size: 44px;
      }

      & span {
        color: #f16001;
      }
    }

    & .input-box {
      & input {
        width: 100%;
        height: 70px;
        line-height: 70px;
        padding-left: 40px;
        border: 2px solid #ddecf3;
        color: #6b93aa;

        &::placeholder {
          opacity: 1;
          color: #6b93aa;
        }
      }

      & button {
        background: #06f;
        color: $white;
        height: 70px;
        line-height: 70px;
        padding: 0 80px;
        font-weight: 700;
        border-radius: 40px;
        border: 0;
        margin-top: 40px;
        @media #{$xs} {
          padding: 0 60px;
        }
      }
    }
  }

  & .map {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 47%;

    @media #{$md} {
      width: 0;
    }

    @media #{$xs} {
      width: 0;
    }

    & iframe {
      width: 100%;
      height: 100%;
    }
  }
}
