.top-Icon {
  text-align: center;
}

.top-Icon > i {
  font-size: 60px;
  color: #fff;
}

.box-title {
  text-align: center;
  margin-top: 18px;
}

.box-title > span {
  font-weight: bold;
}

.text {
  text-align: center;
}

.cardBody {
  padding: 10px;
}

.cardBody > p {
  margin: 10px 0px;
  color: rgba(13, 23, 48, 0.64);
}
.cardBody > p > a {
  color: #c36;
  padding: 0px 10px;
}

#visitSite {
  background: #2d589a;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.nice-select.open .list {
  height: auto;
  overflow-y: scroll;
  margin-left: 25%;
}

.nice-select.form-control {
  width: 100%;
}
ul.list {
  height: 500px;
}

.course-details-item {
  padding: 25px 0px;
}

.blog-Content {
  padding: 24px 0px;
}

.reserveFooter {
  text-align: center;
  position: absolute;
  bottom: 0px;
  padding: 12px;
  border-top: 2px solid #00326d;
  color: #9bc9ff;
  width: 100%;
}

ul.contactInfo > li {
  color: #fff;
  margin: 10px 0px;
}

.contactInfo > li > i {
  margin-right: 10px;
}

p {
  text-align: justify;
}

div#category-select {
  margin: 0px 2.5%;
}

div#country-select {
  margin: 0px 2.5%;
}

div#year-select {
  margin: 0px 2.5%;
}

div#month-select {
  margin: 0px 2.5%;
}
div#course-type-select {
  margin: 0px 2.5%;
}

.card-body > div > ul > li {
  margin: 10px 0px;
}
span.prev-similar.slick-arrow {
  position: absolute;
  bottom: -50px;
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  left: 74px;
}

.modal-dialog {
  max-width: 40% !important;
  top: 10%;
}
span.next-similar.slick-arrow {
  position: absolute;
  bottom: -50px;
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  right: 74px;
}

.buttons-container {
  position: absolute;
  right: 45px;
  margin: 14px;
  z-index: 999;
}
