/*===========================
    21.FAQ css 
===========================*/

.faq-area {
  padding-top: 110px;
  padding-bottom: 96px;

  & .faq-title {
    & .title {
      font-size: 46px;
      @media #{$lg} {
        font-size: 38px;
      }
      @media #{$xs} {
        font-size: 32px;
      }

      & span {
        color: #bb51ff;
      }
    }

    & p {
      font-size: 20px;
      line-height: 30px;
      @media #{$lg} {
        font-size: 18px;
      }
      @media #{$md} {
        font-size: 18px;
        padding-right: 150px;
      }
      @media #{$xs} {
        font-size: 16px;
      }
    }

    &.faq-title-2 {
      & .title {
        & span {
          color: $theme-color;
        }
      }
    }
  }

  & .faq-accordion {
    padding-top: 35px;
    @media #{$md} {
      padding-top: 0;
    }
    @media #{$xs} {
      padding-top: 0;
    }

    & .accordion {
      border: 2px solid transparent;

      & .card {
        margin-bottom: 20px;
        box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
        border-radius: 0 !important;
        border: 2px solid #ebf1f6;
        box-shadow: none;

        & .card-header {
          border-bottom: 0;
          padding: 0;
          margin: 0;
          background-color: transparent !important;

          & a {
            line-height: 80px;
            padding: 0 0 0 40px;
            text-decoration: none;
            font-weight: 600;
            font-size: 16px;
            width: 100%;
            color: #6b93aa;
            position: relative;

            @media #{$lg} {
              padding: 0 0 0 20px;
              font-size: 14px;
            }

            & i {
              color: $theme-color;
              padding-right: 20px;

              @media #{$lg} {
                padding-right: 10px;
              }
            }

            @media #{$xs} {
              padding: 10px 30px 10px 20px;
              line-height: 20px;
              font-size: 15px;
            }

            @media #{$sm} {
              padding: 0 0 0 20px;
              line-height: 60px;
              font-size: 16px;
            }

            &.collapsed {
              color: #6b93aa;

              @media #{$lg} {
                padding: 0 0 0 20px;
                font-size: 14px;
              }

              &::before {
                content: "\f067";
                color: #0066ff;
              }
            }

            &::before {
              position: absolute;
              content: "\f068";
              right: 36px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 14px;
              font-family: "Font Awesome 5 Pro";
              color: #0066ff;
              height: 40px;
              width: 40px;
              border-radius: 50%;
              background: #ecf9ff;
              text-align: center;
              line-height: 40px;

              @media #{$xs} {
                right: 18px;
              }
            }
          }
        }

        & .card-body {
          padding: 0 30px 14px 40px !important;

          @media #{$lg} {
            padding: 0 30px 14px 20px !important;
          }

          @media #{$xs} {
            padding: 10px 0px 20px 20px !important;
          }

          @media #{$sm} {
            padding: 0 40px 14px 20px !important;
          }

          & p {
            margin-top: -10px;
            font-size: 14px;
            @media #{$xs} {
              font-size: 13px;
            }
          }
        }

        &.show {
          .card-body {
            padding: 0 40px 14px 40px !important;

            & p {
              margin-top: -10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.faq-answer-area {
  position: relative;
  padding-top: 109px;
  padding-bottom: 120px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -o-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    opacity: 0.8;
  }

  & .section-title {
    & .title {
      color: $white;
      @media #{$lg} {
        font-size: 54px;
      }
      @media #{$xs} {
        font-size: 26px;
      }
      @media #{$sm} {
        font-size: 36px;
      }
    }

    & p {
      color: $white;
    }
  }

  & form {
    & .input-box {
      position: relative;

      & input {
        width: 100%;
        height: 80px;
        line-height: 80px;
        background-color: transparent;
        border: 2px solid #859af4;
        padding-left: 45px;
        color: $white;

        &::placeholder {
          color: $white;
          opacity: 1;
        }
      }

      & > i {
        position: absolute;
        right: 45px;
        top: 40px;
        transform: translateY(-50%);
        color: $white;
      }

      & textarea {
        width: 100%;
        height: 180px;
        background-color: transparent;
        border: 2px solid #859af4;
        padding-left: 45px;
        padding-top: 30px;
        color: $white;
        resize: none;

        &::placeholder {
          opacity: 1;
          color: $white;
        }
      }

      & button {
        margin-top: 40px;
        background: #24ffce;
        padding: 0 60px;
        height: 60px;
        line-height: 60px;
        color: #001b61;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        border-radius: 30px;
        box-shadow: 0px 8px 16px 0px rgba(0, 240, 255, 0.3);
        & i {
          padding-right: 8px;
        }
      }
    }
  }
  &.faq-answer-area-2 {
    &::before {
      background-color: rgb(0, 26, 63);
      opacity: 0.902;
      background-image: none;
    }
    & .section-title {
      & > span {
        width: 0;
        height: 0;
        border-top: 18px solid #24ffcd;
        border-right: 18px solid transparent;
      }

      & .title {
        color: $white;
        padding-top: 10px;
        padding-bottom: 33px;
      }
    }
    & form {
      & .input-box {
        & input {
          border-color: rgba(255, 255, 255, 0.12);
        }
        & textarea {
          border-color: rgba(255, 255, 255, 0.12);
        }
      }
    }
  }
}

.bg {
  border-style: solid;
  border-width: 2px;

  background-color: rgba(0, 26, 63, 0);
  opacity: 0.12;
  position: absolute;
  left: 371px;
  top: 3973px;
  width: 374px;
  height: 74px;
  z-index: 231;
}
