/*===========================
    8.PLAY css 
===========================*/

.play-area {
  padding-top: 108px;
  margin-bottom: -110px;
  position: relative;
  z-index: 5;

  & .section-title {
    & .title {
      padding-bottom: 55px;

      & span {
        color: $theme-color;
      }
    }
    &.section-title-2 {
      & .title {
        padding-bottom: 7px;
        & span {
          color: #ff65a6;
        }
      }
    }
  }

  & .play-thumb {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: -90px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 138px solid #0066ff;
      border-right: 138px solid transparent;
    }

    &::after {
      position: absolute;
      content: "";
      left: -25px;
      top: 58%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 70px solid #ff65a6;
      border-right: 70px solid transparent;
    }

    & img {
      width: 100%;
    }

    & .play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      & a {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        text-align: center;
        line-height: 200px;
        font-size: 30px;
        color: $heading-color;
        background: $white;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.5);
        @media #{$md} {
          height: 100px;
          width: 100px;
          line-height: 100px;
          font-size: 24px;
        }
        @media #{$xs} {
          height: 80px;
          width: 80px;
          line-height: 80px;
          font-size: 24px;
        }
      }
    }
  }
  & .hello-btn {
    position: absolute;
    top: 50%;
    right: -90px;
    transform: translateY(-50%);
    @media #{$lg} {
      right: 0px;
    }
    @media #{$md} {
      display: none;
    }
    @media #{$xs} {
      display: none;
    }
    & a {
      background: #24ffce;
      color: $heading-color;
      line-height: 70px;
      height: 70px;
      padding: 0 80px;
      border-radius: 40px 40px 40px 0px;
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 1px;
      box-shadow: 0px 20px 40px 0px rgba(36, 255, 206, 0.2);
    }
  }
  &.play-area-2 {
    margin-bottom: 0;
    padding-bottom: 120px;
    padding-top: 103px;
  }
}
