/*===========================
    16.POLITE css 
===========================*/

.polite-area {
  & .polite-content {
    background: $white;
    padding: 65px 75px 75px;
    @media #{$lg} {
      padding: 65px 30px 75px;
    }
    @media #{$xs} {
      padding: 65px 15px 75px;
    }
    @media #{$sm} {
      padding: 65px 60px 75px;
    }
    & .title {
      font-size: 48px;
      line-height: 60px;
      padding-bottom: 58px;
      @media #{$lg} {
        font-size: 42px;
        line-height: 52px;
      }
      @media #{$xs} {
        font-size: 28px;
        line-height: 38px;
      }
      @media #{$sm} {
        font-size: 40px;
        line-height: 50px;
      }
      & span {
        color: #30bcff;
      }
    }
    & .polite-item {
      position: relative;
      padding-left: 90px;
      @media #{$xs} {
        padding-left: 0;
      }
      & h4 {
        font-size: 20px;
        @media #{$xs} {
          font-size: 16px;
        }
      }
      & img {
        position: absolute;
        top: 0;
        left: 0;
        @media #{$xs} {
          position: inherit;
          top: -15px;
        }
      }
      & p {
        font-size: 14px;
        line-height: 26px;
        padding-top: 8px;
      }
    }
  }
}
