/*===========================
    7.FEATURES css 
===========================*/

.features-area {
  padding-top: 100px;
  background-position: right top;
  background-size: 22%;
  position: relative;

  @media #{$md} {
    background-size: 0%;
  }

  @media #{$xs} {
    background-size: 0%;
  }

  & .features-margin {
    @media #{$lg} {
      padding-left: 0;
      padding-right: 0;
    }

    @media #{$md} {
      padding-left: 0;
      padding-right: 0;
    }

    @media #{$xs} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 90px;
    width: 100%;
    background: $white;
  }

  & .single-features {
    background: $white;
    padding: 60px;
    position: relative;
    border-radius: 7px;
    border: 1px solid #022d623b;
    min-height: 400px;

    @media #{$laptop} {
      padding: 60px 40px 40px;
    }

    @media #{$lg} {
      padding: 50px 24px 40px;
    }

    @media #{$md} {
      padding: 40px 20px 20px;
    }

    @media #{$xs} {
      padding: 35px 10px 10px;
    }

    @media #{$sm} {
      padding: 55px 30px 26px;
    }

    // &::before {
    //   position: relative;
    //   content: "";
    //   width: 0;
    //   height: 0;
    //   border-bottom: 18px solid $white;
    //   border-left: 18px solid transparent;
    //   left: 0;
    //   top: -40px;
    // }

    & .icon-contianr {
      padding: 12px 0px;
      display: flex;
      justify-content: center;
    }

    & .title {
      color: #444;
      font-size: 32px;
      line-height: 44px;
      text-align: center;

      @media #{$laptop} {
        font-size: 27px;
        line-height: 37px;
      }
    }

    & p {
      color: #666;
      padding-top: 12px;
      text-align: center;
    }

    &.item-2 {
      background: $white;

      &::before {
        border-bottom: 18px solid #0066ff;
      }

      & .title {
        color: $heading-color;

        & span {
          display: inline;
          color: #ff65a6;
        }
      }

      & p {
        color: #6b93aa;
      }
    }

    &.item-3 {
      background: #8976ff;
    }

    &.item-4 {
      background: #30bcff;
    }
  }
}

.core-features-area {
  & .section-title {
    & .title {
      & span {
        color: $theme-color;
      }
    }
  }

  & .features-thumb {
    & img {
      width: 100%;
    }
  }

  & .features-content {
    @media #{$md} {
      margin-left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @media #{$xs} {
      margin-left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    & .title {
      font-size: 50px;
      line-height: 60px;
      color: $heading-color;
      padding-bottom: 18px;
      @media #{$lg} {
        font-size: 42px;
        line-height: 42px;
      }
      @media #{$xs} {
        font-size: 28px;
        line-height: 38px;
      }

      & span {
        display: inline;
        color: #ff50af;
      }
    }

    & > span {
      font-size: 24px;
      line-height: 36px;
      color: #0aaea7;
      padding-bottom: 5px;
      @media #{$lg} {
        font-size: 22px;
        line-height: 32px;
      }
      @media #{$xs} {
        font-size: 20px;
        line-height: 30px;
      }
    }

    & p {
      line-height: 28px;
      padding-top: 10px;
    }

    & a {
      line-height: 60px;
      border-radius: 30px;
      background: #24ffce;
      font-size: 14px;
      font-weight: 700;
      color: $heading-color;
      border: 0;
      padding: 0 55px;
      @include transition(0.3s);
      margin-top: 35px;
      & i {
        padding-right: 5px;
      }
      &:hover {
        background: $heading-color;
        color: $white;
        border-color: $heading-color;
      }
    }
  }
  & .nav {
    border-bottom: 2px solid #e9f3ff;
    margin-bottom: 50px;
    & li {
      & a {
        border: 0;
        color: #6b93aa;
        text-transform: capitalize;
        font-weight: 700;
        border-radius: 0;
        padding-bottom: 30px;
        @media #{$md} {
          padding: 7px 0px 30px;
          font-size: 14px;
        }
        &.active {
          background-color: transparent;
          border-bottom: 4px solid #0066ff;
          color: $heading-color;
        }
      }
    }
  }
}
