/*===========================
    6.ADVANCE COURSES css 
===========================*/

.advance-courses-area {
  padding-bottom: 90px;
  direction: ltr;

  & .section-title {
    & .title {
      padding-bottom: 24px;
      color: rgba(13, 23, 48, 0.87);
      @media #{$lg} {
        font-size: 50px;
        line-height: 60px;
      }

      & span {
        color: #98d16e;
      }
    }

    &.section-title-2 {
      & p {
        padding: 0 20px 46px 0;

        @media #{$md} {
          padding: 0 130px 46px 0;
        }
      }
    }
  }

  & .courses-active {
    & .slick-arrow {
      position: absolute;
      top: -160px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      text-align: center;
      line-height: 56px;
      border: 2px solid #ebecf6;
      color: #87a1c1;
      cursor: pointer;
      @include transition(0.3s);
      z-index: 9;

      @media #{$lg} {
        top: -100px;
      }

      @media #{$md} {
        top: -100px;
      }

      &:hover {
        background: $theme-color;
        color: $white;
        border-color: $theme-color;
        box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
      }

      &.ar {
        right: 95px;
      }

      &.next {
        right: 15px;
      }
    }
  }

  &.advance-courses-area-2 {
    background: #354cff;

    & .courses-active {
      & .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        color: $white;

        &:hover {
          background: #00ffd8;
          border-color: #00ffd8;
          color: $heading-color;
        }

        &.prev {
          left: -65px;
        }

        &.next {
          right: -65px;
        }
      }
    }
  }
  &.mentor-course {
    position: relative;
    padding-top: 110px;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: -moz-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(0, 144, 255) 100%
      );
      background-image: -webkit-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(0, 144, 255) 100%
      );
      background-image: -ms-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(0, 144, 255) 100%
      );
      background-image: -o-linear-gradient(
        0deg,
        rgb(36, 0, 255) 0%,
        rgb(0, 144, 255) 100%
      );
      opacity: 0.8;
    }
    & .section-title {
      & .title {
        color: #000;
        padding-bottom: 8px;
        & span {
          color: #000;
        }
      }
      & p {
        color: #000;
        padding: 0 60px 45px;

        @media #{$xs} {
          padding: 0 0px 45px;
        }
      }
    }
  }
}

.single-courses {
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  margin-bottom: 30px;

  & .courses-thumb {
    position: relative;
    overflow: hidden;
    z-index: 5;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: -moz-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      background-image: -webkit-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      background-image: -ms-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      background-image: -o-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: -1;
    }

    & img {
      width: 100%;
      transform: scale(1);
      @include transition(0.3s);
      z-index: -2;
    }

    & .courses-review {
      position: absolute;
      left: 30px;
      bottom: 20px;
      z-index: 1;

      & span {
        font-size: 14px;
        color: #999;

        & span {
          color: $white;
          font-weight: 700;
        }

        & i {
          color: #ffea00;
        }
      }
    }

    & .corses-thumb-title {
      position: absolute;
      bottom: 0;

      &.item-2 {
        & span {
          color: $theme-color;
        }
      }

      &.item-3 {
        & span {
          color: #2687ff;
        }
      }

      & span {
        background: $white;
        color: #ff9595;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        height: 36px;
        line-height: 36px;
        padding: 0;
        padding: 0 23px;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  & .courses-content {
    padding: 37px;
    background: $white;
    max-height: 330px;
    min-height: 330px;

    @media #{$lg} {
      padding: 20px;
    }

    @media #{$xs} {
      padding: 20px;
    }

    @media #{$sm} {
      padding: 37px;
    }

    & .title {
      line-height: 30px;
      font-size: 21px;
      @include transition(0.3s);

      @media #{$lg} {
        font-size: 18px;
      }

      @media #{$md} {
        font-size: 18px;
      }

      @media #{$xs} {
        font-size: 17px;
      }

      @media #{$sm} {
        font-size: 24px;
      }
    }

    & .courses-info {
      align-items: center;
      padding-top: 20px;
      position: absolute;
      bottom: 10px;

      & .item {
        position: relative;

        & img {
          position: absolute;
          left: 0;
        }

        & p {
          font-weight: 700;
          color: #2079ff;
          font-size: 14px;
          padding-left: 40px;
        }
      }

      & span {
        color: #53cfa5;
        background: #e6fff2;
        border-radius: 5px;
        height: 35px;
        line-height: 45px;
        padding: 0 15px;
        font-weight: 700;
        @include transition(0.3s);
        top: 20;
      }
    }

    & ul {
      border-top: 1px solid #e2f4ff;
      margin-top: 25px;
      padding-top: 20px;

      & li {
        display: inline-block;
        font-size: 14px;
        color: #99b7d1;
        margin-right: 15px;

        @media #{$lg} {
          margin-right: 10px;
          font-size: 13px;
        }

        @media #{$md} {
          margin-right: 12px;
          font-size: 13px;
        }

        @media #{$xs} {
          margin-right: 12px;
          font-size: 12px;
        }

        & i {
          padding-right: 5px;
        }
      }
    }
  }

  &:hover {
    & .courses-content {
      & .title {
        color: #002d62;
      }

      & .courses-info {
        & span {
          background: #53cfa5;
          color: $white;
        }
      }
    }

    & .courses-thumb {
      & img {
        transform: scale(1.1);
      }
    }
  }
}
