/*===========================
    22.PRICING css 
===========================*/

.pricing-area {
  padding-bottom: 153px;
  & .tab-btns {
    & ul {
      display: inline-block;
      background: $white;
      height: 80px;
      line-height: 76px;
      border-radius: 50px;
      border: 2px solid #e6efff;
      padding: 0 10px;

      & li {
        display: inline-block;

        & a {
          padding: 0;
          line-height: 60px;
          padding: 0 50px;
          font-weight: 700;
          color: #809fb4;
          border-radius: 30px;
          opacity: 0.5;
          @media #{$xs} {
            font-size: 13px;
            padding: 0 16px;
          }
          @media #{$sm} {
            font-size: 16px;
            padding: 0 50px;
          }

          &.active {
            background: #06f;
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }
  & .pricing-table {
    & .single-table {
      padding: 50px 0 60px;
      box-shadow: 0 8px 16px 20px rgba(220, 235, 255, 0.3);

      & > span {
        color: #809fb4;
        font-weight: 700;
        padding-bottom: 15px;
      }
      & .title {
        font-size: 70px;
        padding-bottom: 15px;
        @media #{$lg} {
          font-size: 48px;
        }
        & sub {
          font-size: 16px;
          color: #809fb4;
        }
      }
      & ul {
        & li {
          font-size: 16px;
          color: #809fb4;
          line-height: 40px;
          & i {
            color: rgb(108, 218, 189);
            padding-right: 8px;
          }
        }
      }
      & a {
        font-size: 16px;
        font-weight: 700;
        color: $white;
        background: #ff7891;
        height: 60px;
        line-height: 60px;
        padding: 0 60px;
        border-radius: 40px;
        margin-top: 15px;
      }
      &.item-2 {
        & a {
          background: #30bcff;
        }
      }
      &.item-3 {
        position: relative;
        z-index: 10;
        &::before {
          position: absolute;
          height: 100%;
          width: 100%;
          content: "";
          top: 0;
          left: 0;
          background: #704fff;
          z-index: -1;
          transform: scale(1.12);
          @media #{$md} {
            transform: scale(1);
          }
          @media #{$xs} {
            transform: scale(1);
          }
        }
        & span {
          color: $white;
        }
        & .title {
          color: $white;
          & sub {
            color: $white;
          }
        }
        & ul {
          & li {
            color: $white;
            & i {
              color: $white;
            }
          }
        }
        & a {
          background: #330fd1;
        }
      }
      &.item-4 {
        & a {
          background: #6cdabd;
        }
      }
    }
  }
}
