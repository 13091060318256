/*===========================
    2.HEADER css 
===========================*/

.header-area {
  & .header-top {
    padding-left: 30px;
    padding-right: 30px;
    height: 110px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9f1ff;

    @media #{$xs} {
      padding-left: 0px;
      padding-right: 0px;
      height: 120px;
    }

    @media #{$sm} {
      padding-left: 15px;
      padding-right: 15px;
      height: 110px;
    }

    & .header-logo {
      & form {
        margin-left: 80px;

        @media #{$lg} {
          margin-left: 20px;
        }

        @media #{$md} {
          margin-left: 20px;
        }

        & .input-box {
          position: relative;

          & i {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            color: #06f;
          }

          & input {
            height: 50px;
            line-height: 50px;
            border-radius: 30px;
            padding-left: 64px;
            background: #f7fbff;
            border: 0;
            width: 400px;
            color: #acc4dd;
            font-size: 14px;

            @media #{$lg} {
              width: 280px;
            }

            @media #{$md} {
              width: 240px;
            }

            &::placeholder {
              color: #acc4dd;
              opacity: 1;
            }
          }
        }
      }
    }

    & .header-btns {
      & ul {
        & li {
          display: inline-block;

          & a {
            font-size: 14px;
            color: $heading-color;
            font-weight: 700;
            margin-left: 35px;
            line-height: 50px;

            @media #{$md} {
              margin-left: 30px;
            }

            & i {
              color: #30bcff;
              margin-right: 5px;
            }
          }

          &:first-child {
            & a {
              margin-left: 0;
            }
          }
        }
      }
    }

    & .trial-btns {
      & a {
        height: 50px;
        line-height: 50px;
        border: 2px solid #ff9595;
        color: #ff9595;
        padding: 0 50px;
        border-radius: 30px;
        margin-left: 45px;
        font-weight: 700;
        font-size: 14px;

        @media #{$lg} {
          padding: 0 20px;
          margin-left: 12px;
        }
      }
    }
  }

  &.header-area-3 {
    background: #f5fbff;

    & .header-top {
      height: 155px;
      padding-bottom: 13px;
    }

    & .header-btns {
      & form {
        margin-left: 80px;

        @media #{$lg} {
          margin-left: 20px;
        }

        @media #{$md} {
          margin-left: 20px;
        }

        & .input-box {
          position: relative;

          & i {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            color: #06f;
          }

          & input {
            height: 50px;
            line-height: 50px;
            border-radius: 30px;
            padding-left: 64px;
            background: $white;
            border: 0;
            width: 290px;
            color: #acc4dd;
            font-size: 14px;

            @media #{$lg} {
              width: 280px;
            }

            @media #{$md} {
              width: 240px;
            }

            &::placeholder {
              color: #acc4dd;
              opacity: 1;
            }
          }
        }
      }

      & .trial-btns {
        & a {
          color: $heading-color;
          margin-left: 30px;
          white-space: nowrap;
        }
      }
    }

    & .header-menu {
      & .navigation {
        background-color: transparent;
        top: 0px;
        padding: 0 0px;

        &.sticky {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          animation: sticky 1s;
          background-color: $white;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
          padding: 0;

          & .navbar {
            & .navbar-nav {
              align-items: center;
              & .nav-item {
                & a {
                  font-size: 14px;
                  line-height: 80px;

                  @media #{$md} {
                    line-height: 40px;
                  }

                  @media #{$xs} {
                    line-height: 40px;
                  }

                  &::before {
                    top: 0;
                  }
                }
              }
            }

            & .navbar-collapse {
              @media #{$md} {
                top: 100% !important;
              }

              @media #{$xs} {
                top: 100%;
              }
            }
          }
        }

        & .navbar {
          background: $white;

          & .navbar-nav {
            align-items: center;
            padding-left: 40px;
            @media #{$md} {
              padding-left: 0;
            }
            @media #{$xs} {
              padding-left: 0;
            }

            &.img.pageLogo {
              height: 70px;
            }

            & .nav-item {
              & a {
                line-height: 70px;
                color: #59879e;
                @media #{$md} {
                  line-height: 40px;
                }
                @media #{$xs} {
                  line-height: 40px;
                }

                &::before {
                  display: none;
                }
              }

              & .sub-menu {
                & li {
                  & a {
                    line-height: 45px;

                    &:hover {
                      background: #06f;
                    }
                  }
                }
              }
            }
          }

          & .navbar-item {
            & .menu-icon {
              padding: 25px 10px 25px 0;
              & ul {
                & li {
                  & a {
                    color: #b8cce8;

                    &:hover {
                      color: #0066ff;
                    }
                  }
                }
              }
            }

            & .bars-area {
              & span {
                & i {
                  cursor: pointer;
                  line-height: 60px;
                  background: #0066ff;
                  color: $white;
                  padding: 0 20px;
                  font-size: 24px;
                  margin-right: 5px;
                  margin-left: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.navigation {
  padding: 0;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: transparent;
  padding-left: 70px;
  padding-right: 70px;
  background: $headerBackgroundColor;

  @media #{$laptop} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$lg} {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media #{$md} {
    padding-left: 0px;
    padding-right: 0px;
    padding: 32px 0;
  }

  @media #{$xs} {
    padding-left: 20px;
    padding-right: 20px;
    padding: 32px 0;
  }

  @media #{$sm} {
    padding-left: 20px;
    padding-right: 20px;
    padding: 32px 0;
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    animation: sticky 1s;
    background-color: $white;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
    padding: 0;

    @media #{$md} {
      padding: 20px 0;
    }

    @media #{$xs} {
      padding: 20px 0;
    }

    & .navbar {
      & #navbarFive {
        padding: 0 20%;
      }

      & .navbar-nav {
        align-items: center;
        & .nav-item {
          & a {
            line-height: 80px;
            font-size: 14px;

            @media #{$md} {
              line-height: 40px;
            }

            @media #{$xs} {
              line-height: 40px;
            }

            &::before {
              top: 0;
            }
          }
        }
      }

      & .navbar-collapse {
        @media #{$md} {
          top: 155%;
        }

        @media #{$xs} {
          top: 155%;
        }
      }
    }
  }

  & .navbar {
    position: relative;
    padding: 0;

    & .navbar-toggler {
      & .toggler-icon {
        width: 30px;
        height: 2px;
        background-color: $theme-color;
        margin: 5px 0;
        display: block;
        position: relative;
        @include transition(0.3s);
      }

      &.active {
        & .toggler-icon {
          &:nth-of-type(1) {
            @include transform(rotate(45deg));
            top: 7px;
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            @include transform(rotate(135deg));
            top: -7px;
          }
        }
      }
    }

    & .navbar-collapse {
      @media #{$md} {
        position: absolute;
        top: 190%;
        left: 0;
        width: 100%;
        background-color: $white;
        z-index: 8;
        padding: 10px 16px;
        box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
      }

      @media #{$xs} {
        position: absolute;
        top: 190%;
        left: 0;
        width: 100%;
        background-color: $white;
        z-index: 8;
        padding: 10px 16px;
        box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
      }

      @media #{$sm} {
        top: 190%;
      }
    }

    & .navbar-nav {
      align-items: center;
      @media #{$md} {
        margin-right: 0;
      }

      @media #{$xs} {
        margin-right: 0;
      }

      & .nav-item {
        position: relative;

        & a {
          font-size: 14px;
          font-weight: 700;
          padding: 0;
          color: #cdced4;
          text-transform: capitalize;
          position: relative;
          margin: 0;
          line-height: 80px;
          @include transition(0.3s);
          padding-right: 45px;

          & > i {
            @media #{$md} {
              display: none;
            }

            @media #{$xs} {
              display: none;
            }
          }

          @media #{$lg} {
            padding-right: 25px;
            font-size: 14px;
          }

          @media #{$md} {
            padding: 0;
            display: block;
            color: $heading-color;
            border: 0;
            margin: 0;
            line-height: 40px;
          }

          @media #{$xs} {
            padding: 0;
            display: block;
            color: $heading-color;
            border: 0;
            margin: 0;
            line-height: 40px;
          }

          &::before {
            position: absolute;
            content: "";
            left: 0;
            top: -1px;
            height: 2px;
            width: 0%;
            background: $theme-color;
            @include transition(0.3s);

            @media #{$md} {
              display: none;
            }

            @media #{$xs} {
              display: none;
            }
          }

          &:hover {
            color: $theme-color;

            &::before {
              width: 100%;
            }
          }
        }

        & .sub-menu {
          position: absolute;
          top: 100%;
          width: 260px;
          background-color: $white;
          opacity: 0;
          visibility: hidden;
          @include transition(0.3s);
          z-index: 99;
          @include box-shadow(0 2px 6px 0 rgba(0, 0, 0, 0.16));

          @media #{$md} {
            position: relative !important;
            width: 100% !important;
            left: 0 !important;
            top: auto !important;
            opacity: 1 !important;
            visibility: visible !important;
            display: none;
            right: auto;
            @include transform(translateX(0%));
            @include transition(none);
            @include box-shadow(none);
            text-align: left;
            border-top: 0;
          }

          @media #{$xs} {
            position: relative !important;
            width: 100% !important;
            left: 0 !important;
            top: auto !important;
            opacity: 1 !important;
            visibility: visible !important;
            display: none;
            right: auto;
            @include transform(translateX(0%));
            @include transition(none);
            @include box-shadow(none);
            text-align: left;
            border-top: 0;
          }

          & > li {
            position: relative;

            & .sub-nav-toggler {
              color: $heading-color;
              @include transition(0.3s);
            }

            & a {
              display: block;
              padding: 8px 24px;
              position: relative;
              color: $heading-color;
              @include transition(0.3s);
              border-radius: 0;
              margin: 0 0;
              line-height: 30px;

              &::hover {
                color: #0066ff;
              }
              &::before {
                display: none;
              }

              & i {
                float: right;
                font-size: 14px;
                margin-top: 5px;

                @media #{$md} {
                  display: none;
                }

                @media #{$xs} {
                  display: none;
                }
              }

              & .sub-nav-toggler {
                & i {
                  display: inline-block;
                }
              }
            }

            & .sub-menu {
              right: auto;
              left: 100%;
              top: 0;
              opacity: 0;
              visibility: hidden;
              @include transition(0.3s);

              @media #{$md} {
                padding-left: 30px;
              }

              @media #{$xs} {
                padding-left: 30px;
              }

              & li {
                & a {
                  &::before {
                    display: none;
                  }
                }
              }
            }

            &:hover {
              & .sub-menu {
                opacity: 1;
                visibility: visible;
              }

              & .sub-nav-toggler {
                color: $white;
              }

              & > a {
                background-color: $theme-color;
                color: #fff !important;

                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }

        &:hover {
          & .sub-menu {
            opacity: 1;
            visibility: visible;
            top: 100%;
          }
        }

        .sub-nav-toggler {
          display: none;

          @media #{$md} {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px 14px;
            font-size: 16px;
            background: none;
            border: 0;
            color: $heading-color;
          }

          @media #{$xs} {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px 14px;
            font-size: 16px;
            background: none;
            border: 0;
            color: $heading-color;
          }

          & img {
            width: 30%;
          }
        }

        &.active {
          & a {
            color: $theme-color;

            &::before {
              width: 80%;
            }
          }
        }

        &.active {
          & a {
            color: $theme-color;
          }
        }
      }
    }

    & .navbar-item {
      & .menu-btns {
        & ul {
          & li {
            display: inline-block;
            margin-right: 60px;

            @media #{$lg} {
              margin-right: 30px;
            }

            @media #{$xs} {
              margin-right: 0px;
              margin-left: 15px;
            }

            @media #{$sm} {
              margin-right: 0px;
              margin-left: 30px;
            }

            & a {
              font-weight: 700;
              color: #cdced4;
              @include transition(0.3s);

              &:hover {
                color: $theme-color;
              }
            }
          }
        }
      }

      & .menu-icon {
        & ul {
          & li {
            display: inline-block;

            & a {
              color: #cdced4;
              margin-left: 23px;
              @include transition(0.3s);

              &:hover {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }

  &.navigation-2 {
    top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;

    @media #{$md} {
      padding: 32px 0;
    }

    @media #{$xs} {
      padding: 32px 0;
    }

    &::before {
      position: absolute;
      content: "";
      left: -50px;
      top: 0;
      height: 100%;
      width: 290px;
      background: #00a5b8;
      transform: skewX(28deg);

      @media #{$laptop} {
        width: 250px;
      }

      @media #{$lg} {
        left: -130px;
      }

      @media #{$xs} {
        left: -130px;
      }

      @media #{$sm} {
        left: -105px;
      }
    }

    &::after {
      position: absolute;
      content: "";
      right: -50px;
      top: 0;
      height: 100%;
      width: 290px;
      background: #2687ff;
      transform: skewX(-28deg);
      z-index: -1;

      @media #{$laptop} {
        width: 250px;
      }

      @media #{$lg} {
        width: 0px;
      }

      @media #{$md} {
        width: 0px;
      }

      @media #{$xs} {
        width: 0px;
      }
    }

    & .navbar {
      & .navbar-nav {
        align-items: center;
        & .nav-item {
          & a {
            padding: 0 30px;
            line-height: 110px;

            @media #{$laptop} {
              padding: 0 15px;
            }

            @media #{$lg} {
              padding: 0 18px;
            }

            @media #{$md} {
              line-height: 40px;
            }

            @media #{$xs} {
              line-height: 40px;
            }

            &::before {
              height: 3px;
              top: 0;
            }
          }
        }
      }

      & .navbar-item {
        @media #{$md} {
          position: absolute;
          right: 100px;
          top: 50%;
          transform: translateY(-50%);
        }

        @media #{$xs} {
          position: absolute;
          right: 100px;
          top: 50%;
          transform: translateY(-50%);
        }

        @media #{$sm} {
          position: absolute;
          right: 70px;
          top: 50%;
          transform: translateY(-50%);
        }

        & .menu-btns {
          & ul {
            & li {
              margin-right: 0;
              margin-left: 35px;

              &:first-child {
                margin-left: 0;
              }

              & a {
                color: $heading-color;
                font-size: 14px;

                & i {
                  color: #30bcff;
                  padding-right: 6px;
                }
              }
            }
          }
        }

        & .menu-offer {
          margin-left: 165px;

          @media #{$laptop} {
            margin-left: 70px;
          }

          @media #{$lg} {
            display: none;
          }

          @media #{$md} {
            display: none;
          }

          @media #{$xs} {
            display: none;
          }

          & .title {
            font-size: 30px;
            color: $white;

            @media #{$laptop} {
              font-size: 26px;
            }
          }

          & span {
            color: $white;
          }
        }
      }

      & .navbar-collapse {
        @media #{$md} {
          top: 168%;
        }

        @media #{$xs} {
          top: 168%;
        }
      }
    }
  }
  &.navigation-3 {
    & .navbar {
      & .navbar-collapse {
        top: 100%;
      }
    }
  }
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}
