/*===========================
    5.OFFER css 
===========================*/

.offer-area {
  position: relative;
  background-attachment: fixed;
  margin-bottom: 106px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -moz-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -o-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    opacity: 0.8;
  }

  & .offer-content {
    & span {
      font-size: 26px;
      font-weight: 400;
      color: $white;
      padding-bottom: 25px;

      @media #{$md} {
        font-size: 22px;
      }

      @media #{$xs} {
        font-size: 18px;
      }

      @media #{$sm} {
        font-size: 20px;
      }
    }

    & .title {
      font-size: 70px;
      line-height: 80px;
      color: $white;
      padding-bottom: 3px;

      @media #{$lg} {
        font-size: 60px;
        line-height: 70px;
      }

      @media #{$md} {
        font-size: 46px;
        line-height: 56px;
      }

      @media #{$xs} {
        font-size: 28px;
        line-height: 38px;
      }

      @media #{$sm} {
        font-size: 36px;
        line-height: 46px;
      }
    }

    & ul {
      padding-top: 22px;

      & li {
        display: inline-block;

        & a {
          line-height: 54px;
          border-radius: 30px;
          background: #fff;
          font-size: 14px;
          font-weight: 700;
          color: #001b61;
          border: 2px solid #fff;
          margin: 0 10px;

          @media #{$xs} {
            margin: 0 10px 20px;
          }

          @media #{$sm} {
            margin: 0 10px 0px;
          }

          & i {
            padding-right: 5px;
          }

          & span {
            padding-right: 8px;
          }

          &:hover {
            border-color: #fbadad;
            background-color: #fbadad;
            color: $white;
          }

          &.main-btn-2 {
            border-color: #2d589a;
            background-color: #2d589a;
            color: $white;

            &:hover {
              background: #0051b1;
              border-color: #0051b1;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.offer-2-area {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -o-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    opacity: 0.8;
  }

  & .offer-content {
    & span {
      font-size: 26px;
      color: $white;
      padding-bottom: 20px;
      @media #{$xs} {
        font-size: 16px;
      }
      @media #{$sm} {
        font-size: 20px;
      }
    }
    & .title {
      color: $white;
      font-size: 68px;
      line-height: 80px;
      padding-bottom: 15px;
      @media #{$lg} {
        font-size: 54px;
        line-height: 64px;
      }
      @media #{$xs} {
        font-size: 38px;
        line-height: 48px;
      }
      @media #{$sm} {
        font-size: 52px;
        line-height: 62px;
      }
    }
    & p {
      font-size: 20px;
      line-height: 32px;
      color: $white;
      padding-bottom: 23px;
      @media #{$lg} {
        font-size: 16px;
      }
      @media #{$xs} {
        font-size: 18px;
      }
      @media #{$sm} {
        font-size: 16px;
      }
    }
    & ul {
      padding-top: 22px;

      & li {
        display: inline-block;

        & a {
          line-height: 54px;
          border-radius: 30px;
          background: #24ffce;
          font-size: 14px;
          font-weight: 700;
          color: $heading-color;
          border: 2px solid #24ffce;
          margin: 0 10px;

          @media #{$xs} {
            margin: 0 10px 20px;
          }

          @media #{$sm} {
            margin: 0 10px 0px;
          }

          & i {
            padding-right: 5px;
          }

          & span {
            padding-right: 8px;
          }

          &:hover {
            border-color: #001e92;
            background-color: #001e92;
            color: $white;
          }

          &.main-btn-2 {
            border-color: #001e92;
            background-color: #001e92;
            color: $white;

            &:hover {
              background: #24ffce;
              border-color: #24ffce;
              color: $heading-color;
            }
          }
        }
      }
    }
  }
  & .offer-thumb {
    @media #{$md} {
      text-align: center;
    }
    @media #{$xs} {
      margin-left: 0;
    }
    & img {
      @media #{$xs} {
        width: 100%;
      }
      @media #{$sm} {
        width: auto;
      }
    }
    & .item-2 {
      margin-top: -185px;
      margin-left: 115px;
      @media #{$lg} {
        margin-left: 45px;
      }
      @media #{$xs} {
        margin-top: 30px;
        margin-left: 0;
      }
      @media #{$sm} {
        margin-top: -185px;
        margin-left: 115px;
      }
    }
  }
}
