/*===============================
    28.SHOP css 
================================*/

.shop-area {
  & .shop-sidebar-area {
    padding: 33px 40px 30px;
    @media #{$lg} {
      padding: 33px 20px 30px;
    }
    & .sidebar-title {
      & .title {
        font-size: 20px;
        position: relative;
        &::before {
          height: 4px;
          width: 4px;
          position: absolute;
          content: "";
          background: $theme-color;
          left: 30px;
          top: 12px;
        }
        &::after {
          height: 4px;
          width: 18px;
          position: absolute;
          content: "";
          background: $theme-color;
          left: 8px;
          top: 12px;
        }
      }
    }
    & .sidebar-list {
      padding-top: 17px;
      & ul {
        & li {
          & a {
            color: #75a1af;
            font-size: 14px;
            line-height: 45px;
            @include transition(0.3s);
            & i {
              padding-right: 15px;
            }
            &:hover {
              color: $heading-color;
            }
          }
        }
      }
    }
    & .sidebar-tag {
      padding-top: 21px;
      & ul {
        & li {
          display: inline-block;
          & a {
            color: $theme-color;
            text-decoration: underline;
            margin: 0 8px;
            font-size: 14px;
            font-weight: 700;
            padding-top: 3px;
            padding-bottom: 5px;
            @media #{$lg} {
              margin: 0 5px;
            }
          }
        }
      }
    }
    & .sidebar-subscribe {
      & p {
        font-size: 14px;
        line-height: 24px;
        padding-top: 18px;
        padding-bottom: 15px;
      }
      & .input-box {
        & input {
          width: 100%;
          line-height: 50px;
          padding-left: 30px;
          border: 2px solid #edf1ff;
          color: #7585af;
          font-size: 14px;
          &::placeholder {
            opacity: 1;
            color: #7585af;
            font-size: 14px;
          }
        }
        & button {
          padding: 0;
          width: 100%;
          line-height: 50px;
          border-radius: 0;
          margin-top: -2px;
          margin-bottom: 10px;
          background: $theme-color;
          border-color: $theme-color;
          font-size: 14px;
        }
      }
    }
  }
  & .shop-top-bar {
    position: relative;
    & .input-box {
      position: relative;
      display: inline-block;
      margin-left: 120px;
      @media #{$lg} {
        margin-left: 0;
      }
      @media #{$md} {
        margin-left: 0;
      }
      @media #{$xs} {
        margin-left: 0;
      }
      & input {
        line-height: 60px;
        border: 0;
        border-radius: 30px;
        width: 270px;
        padding-left: 30px;
        color: #94aebf;
      }
      & button {
        position: absolute;
        top: 0;
        right: 0;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: #fff;
        border: 0;
        color: $theme-color;
      }
    }
    & .nice-select {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 160px;
      border: 0;
      line-height: 60px;
      height: auto;
      border-radius: 30px;
      padding-left: 35px;
      color: #42495b;
      font-weight: 700;
      font-size: 14px;
      z-index: 99;
      @media #{$xs} {
        left: 0;
        right: auto;
        top: -50px;
      }
      @media #{$sm} {
        right: 0;
        left: auto;
        top: 50%;
      }
      &::after {
        width: 8px;
        height: 8px;
        right: 40px;
        border-bottom: 2px solid $theme-color;
        border-right: 2px solid $theme-color;
      }
    }
    & p {
      color: #002249;
      font-weight: 700;
      @media #{$xs} {
        padding-top: 30px;
      }
    }
  }
  & .shop-item {
    overflow: hidden;
    & .shop-thumb {
      position: relative;
      & img {
        width: 100%;
      }
      & .shop-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 40, 72, 0.6);
        opacity: 0;
        @include transition(0.5s);
        & ul {
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translate(-50%, -0%));
          white-space: nowrap;
          @include transition(0.5s);
          opacity: 0;
          & li {
            display: inline-block;
            & a {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              text-align: center;
              line-height: 50px;
              background: $white;
              margin: 0 5px;
              @include transition(0.3s);
              &:hover {
                color: $white;
                background: $theme-color;
              }
            }
          }
        }
      }
    }
    & .shop-content {
      padding: 25px 0;
      & .title {
        font-size: 18px;
        font-weight: 600;
        @media #{$lg} {
          font-size: 17px;
        }
        @media #{$md} {
          font-size: 16px;
        }
      }
      & ul {
        padding-top: 5px;
        & li {
          display: inline-block;
          margin: 0 8px;
          &:first-child {
            text-decoration: line-through;
            font-size: 14px;
            color: #b2c0d3;
          }
          &:last-child {
            font-weight: 700;
            color: $theme-color;
            font-size: 14px;
          }
        }
      }
    }
    &:hover {
      & .shop-thumb {
        & .shop-overlay {
          opacity: 1;
          & ul {
            opacity: 1;
            @include transform(translate(-50%, -50%));
          }
        }
      }
    }
  }
}

.sidebar-add {
  position: relative;
  & img {
    width: 100%;
  }
  & span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    line-height: 60px;
    padding: 0 40px;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    white-space: nowrap;
    color: $theme-color;
  }
}

.page-link:focus {
  box-shadow: none;
}

nav {
  & .pagination {
    & li {
      & a {
        border-radius: 50% !important;
        padding: 0;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 51px;
        border-color: $white;
        margin: 0 7px;
        border: 0;
        @include transition(0.3s);
        color: #70a3bb;
        font-size: 14px;
        font-weight: 700;
        @media #{$xs} {
          height: 45px;
          width: 45px;
          line-height: 45px;
          font-size: 14px;
        }
        &:hover {
          background: $theme-color;
          color: $white;
          border-color: $theme-color;
        }
        &.active {
          color: $white;
          background: $theme-color;
        }
      }
      &:first-child {
        & a {
          color: $heading-color;
          &:hover {
            color: $white;
          }
        }
      }
      &:last-child {
        & a {
          color: $heading-color;
          &:hover {
            color: $white;
          }
        }
      }
    }
    &.pagination-2 {
      & li {
        & a {
          background: #f1fcff;
          &:hover {
            background: $theme-color;
          }
          &.active {
            background: $theme-color;
            color: $white;
          }
        }
      }
    }
  }
}

/*shop details*/

.shop-details-area {
  & .shop-product-area {
    @media #{$md} {
      margin-left: 0;
    }
    @media #{$xs} {
      margin-left: 0;
    }
    & .shop-product-item {
      & img {
        width: 100%;
      }
    }
  }
  & .shop-details-content {
    @media #{$md} {
      margin-left: 0;
    }
    @media #{$xs} {
      margin-left: 0;
    }
    & .review {
      margin-top: 12px;
      & ul {
        padding-bottom: 25px;
        & li {
          display: inline-block;
          margin-right: 2px;
          & i {
            color: #ffb400;
          }
          & span {
            font-size: 14px;
            color: #63688e;
          }
        }
      }
      & > span {
        color: $theme-color;
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 5px;
      }
      & .title {
        font-size: 30px;
      }
    }
    & .price {
      margin-bottom: 12px;
      margin-top: 15px;
      & ul {
        & li {
          display: inline-block;
          font-size: 50px;
          color: $theme-color;
          margin-right: 18px;
          &:last-child {
            color: #bfc7d3;
            text-decoration: line-through;
          }
        }
      }
    }
    & .list {
      & .list-1 {
        & li {
          color: $heading-color;
          font-weight: 700;
          line-height: 30px;
        }
      }
      & .list-2 {
        margin-left: 30px;
        & li {
          color: #63688e;
          line-height: 30px;
          &:last-child {
            color: $theme-color;
            font-weight: 700;
          }
        }
      }
    }
    & .overview {
      & .title {
        font-size: 30px;
        padding-bottom: 13px;
        padding-top: 23px;
      }
      & p {
        padding-right: 30px;
      }
      & .list-2 {
        align-items: center;
        margin-top: 35px;
        & ul {
          & li {
            display: inline-block;
            & a {
              height: 50px;
              width: 50px;
              background: #d1edff;
              text-align: center;
              line-height: 50px;
              color: #7fb8dc;
              margin-left: 20px;
              @include transition(0.3s);
              @media #{$xs} {
                margin-left: 0;
                margin-right: 10px;
                margin-top: 20px;
              }
              @media #{$sm} {
                margin-left: 20px;
                margin-right: 0px;
                margin-top: 0px;
              }
              &:hover {
                color: $white;
                background: $theme-color;
              }
            }
          }
        }
      }
      & form {
        & > button {
          background: $theme-color;
          border-color: $theme-color;
          border-radius: 0;
          padding: 0 70px;
          margin-top: 30px;
        }
      }
    }
  }
  & .tab-content {
    & .shop-descriptions-area {
      & .title {
        font-size: 30px;
        padding-top: 15px;
        padding-bottom: 12px;
        @media #{$xs} {
          font-size: 24px;
        }
        @media #{$sm} {
          font-size: 30px;
        }
      }
      & p {
        padding-right: 66px;
        @media #{$md} {
          padding-right: 0;
        }
        @media #{$xs} {
          padding-right: 0;
        }
      }
      & .shop-descriptions-list {
        border-bottom: 1px solid #cbeaff;
        & .shop-list-1 {
          width: 22%;
          @media #{$md} {
            width: 40%;
          }
          @media #{$xs} {
            width: 50%;
          }
          @media #{$sm} {
            width: 50%;
          }
          & li {
            color: $heading-color;
            font-weight: 700;
            line-height: 60px;
          }
        }
        & .shop-list-2 {
          width: 78%;
          @media #{$md} {
            width: 60%;
          }
          @media #{$xs} {
            width: 50%;
          }
          @media #{$sm} {
            width: 50%;
          }
          & li {
            color: #63688e;
            line-height: 60px;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.shop-tab {
  position: relative;
  z-index: 5;
  & img {
    position: absolute;
    top: 25px;
    z-index: -1;
  }
  & .nav {
    & li {
      & a {
        background: $white;
        line-height: 50px;
        padding: 0 65px;
        border-radius: 0;
        margin: 0 10px;
        border: 2px solid #e7ecf0;
        color: #63688e;
        font-weight: 700;
        @media #{$md} {
          padding: 0 40px;
        }
        @media #{$xs} {
          margin-bottom: 20px;
        }
        @media #{$sm} {
          padding: 0 30px;
          margin-bottom: 0;
        }
        &.active {
          background: $theme-color;
          border-color: $theme-color;
        }
      }
    }
  }
}

.product-quantity {
  & button {
    width: 50px;
    height: 50px;
    padding: 0;
    background-color: $white;
    font-size: 18px;
    color: #838b97;
    border: 1px solid #dce1e5;

    @media #{$xs} {
      width: 40px;
      height: 40px;
    }
  }
  & input {
    width: 60px;
    height: 50px;
    border: 1px solid #dce1e5;
    padding: 0 10px;
    text-align: center;
    margin: 0 0;
    color: #838b97;

    @media #{$xs} {
      height: 40px;
    }
  }
}
