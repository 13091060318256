#add_course {
  position: absolute;
  right: 5%;
  margin-top: 10px;
  z-index: 999;
}

.add-course:hover {
  cursor: pointer;
  background-color: #002d62;
}
