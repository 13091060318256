/*===========================
    13.WE DO css 
===========================*/

.we-do-area {
  padding-bottom: 120px;

  .single-we-do {
    & .we-do-thumb {
      position: relative;
      overflow: hidden;
      z-index: 5;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: -moz-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-image: -webkit-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-image: -ms-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-image: -o-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 6;
      }

      & img {
        width: 100%;
        transform: scale(1);
        @include transition(0.3s);
      }

      & h3 {
        font-size: 24px;
        color: $white;
        position: absolute;
        z-index: 7;
        bottom: 40px;
        left: 40px;

        @media #{$lg} {
          font-size: 20px;
          left: 20px;
          bottom: 25px;
        }

        @media #{$xs} {
          font-size: 18px;
          left: 20px;
          bottom: 25px;
        }
      }
    }

    & .we-do-content {
      border: 2px solid #f2f2f9;
      border-top: 0;
      padding: 30px 40px 37px;
      position: relative;

      @media #{$lg} {
        padding: 30px 20px 37px;
      }

      @media #{$xs} {
        padding: 30px 20px 37px;
      }

      @media #{$sm} {
        padding: 30px 30px 37px;
      }

      & .item {
        text-align: right;

        & span {
          display: block;
          font-size: 14px;
          font-weight: 700;
          color: #53cfa5;
        }
      }

      & p {
        color: #6b93aa;
        line-height: 28px;
        padding-top: 20px;
        position: relative;

        @media #{$lg} {
          font-size: 15px;
        }

        @media #{$md} {
          font-size: 15px;
        }

        @media #{$xs} {
          font-size: 15px;
        }

        &::before {
          position: absolute;
          content: "";
          top: -20px;
          left: 0;
          width: 0;
          height: 0;
          border-bottom: 18px solid #0066ff;
          border-left: 18px solid transparent;
        }
      }

      & a {
        height: 50px;
        line-height: 42px;
        border: 2px solid #f2f2f9;
        padding: 0 30px;
        font-size: 14px;
        font-weight: 700;
        color: #6b93aa;
        margin-top: 25px;
        @include transition(0.3s);

        &:hover {
          background: #06f;
          border-color: #06f;
          color: $white;
        }
      }

      & > i {
        position: absolute;
        font-size: 100px;
        color: #f1faff;
        right: 15px;
        bottom: 15px;
        z-index: -1;
      }

      &.content-2 {
        & p {
          &::before {
            border-bottom: 18px solid #ff65a6;
          }
        }
      }

      &.content-3 {
        & p {
          &::before {
            border-bottom: 18px solid #8976ff;
          }
        }
      }
    }

    &:hover {
      & .we-do-thumb {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.we-do-3 {
  padding-top: 120px;
  padding-bottom: 120px;

  & .section-title {
    & > span {
      width: 0;
      height: 0;
      border-top: 18px solid #0066ff;
      border-right: 18px solid transparent;
    }

    & .title {
      color: $heading-color;
      padding-top: 10px;
      padding-bottom: 33px;
    }
  }

  & .single-we-do {
    border: 2px solid #daeeff;
    padding-top: 65px;
    padding-bottom: 65px;

    & i {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      text-align: center;
      line-height: 100px;
      color: #06f;
      font-size: 34px;
      border: 1px solid #eff8ff;
      position: relative;
      z-index: 10;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border: 5px solid transparent;
        border-top-color: #06f;
        border-bottom-color: #06f;
        z-index: -1;
        border-radius: 50%;
        transform: rotate(18deg);
      }
    }

    & .title {
      font-size: 24px;
      text-transform: capitalize;
      padding-top: 35px;
    }

    & p {
      color: #5783a6;
      padding: 0 40px;
      padding-top: 15px;
      @media #{$lg} {
        padding: 0 22px;
      }
      @media #{$xs} {
        padding: 0 10px;
      }
      @media #{$sm} {
        padding: 0 40px;
      }
    }

    & a {
      font-size: 14px;
      color: #5783a6;
      font-weight: 700;
      margin-top: 17px;
    }

    &.item-2 {
      & i {
        color: #fa938f;

        &::after {
          border-top-color: #fa938f;
          border-bottom-color: #fa938f;
        }
      }
    }
    &.item-3 {
      & i {
        color: #ffbd5e;

        &::after {
          border-top-color: #ffbd5e;
          border-bottom-color: #ffbd5e;
        }
      }
    }
  }
}
