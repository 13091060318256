/*===========================
    9.TESTIMONIALS css 
===========================*/

.testimonials-area {
  padding-top: 120px;

  & .testimonials-content {
    & i {
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50%;
      background: #98d16e;
      color: $white;
      font-size: 18px;
      box-shadow: 0px 8px 16px 0px rgba(152, 209, 110, 0.2);
    }

    & .title {
      font-size: 50px;
      line-height: 60px;
      padding-top: 40px;

      @media #{$lg} {
        font-size: 40px;
        line-height: 50px;
      }

      @media #{$md} {
        font-size: 30px;
        line-height: 40px;
      }

      @media #{$xs} {
        font-size: 18px;
        line-height: 28px;
      }

      @media #{$sm} {
        font-size: 32px;
        line-height: 42px;
      }

      & span {
        color: #06f;
      }
    }

    & p {
      font-size: 18px;
      line-height: 32px;
      padding: 27px 170px 0px;
      text-align: center;
      @media #{$lg} {
        padding: 27px 50px 0px;
      }

      @media #{$md} {
        padding: 27px 0px 0px;
        font-size: 15px;
      }

      @media #{$xs} {
        padding: 27px 0px 0px;
        font-size: 16px;
      }

      @media #{$sm} {
        padding: 27px 0px 0px;
        font-size: 16px;
      }
    }

    & h5 {
      font-size: 24px;
      padding-top: 19px;
    }

    & > span {
      color: #ff65a6;
      padding-top: 5px;
    }

    & img {
      display: inline-block;
      padding: 20px 0 5px;
    }
  }

  & .testimonials-active {
    & .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60px;
      width: 60px;
      border-radius: 50%;
      text-align: center;
      line-height: 56px;
      border: 3px solid #e9ecff;
      color: $heading-color;
      font-size: 20px;
      cursor: pointer;
      z-index: 99;
      @include transition(0.3s);

      &.prev {
        left: -60px;

        @media #{$lg} {
          left: 0;
        }

        &:hover {
          border-right-color: #e9ecff;
          border-left-color: #53cfa5;
        }
      }

      &.next {
        right: -60px;

        @media #{$lg} {
          right: 0;
        }
      }

      &:hover {
        border-color: #53cfa5;
        border-left-color: #e9ecff;
      }
    }
  }
}

.testimonials-2-area {
  padding-top: 109px;
  padding-bottom: 186px;

  & .section-title {
    & .title {
      & span {
        color: #53cfa5;
      }
    }
  }

  & .single-testimonials {
    & .testimonials-content {
      border: 2px solid #e7fbff;
      position: relative;
      padding: 60px 20px 35px;

      &::before {
        position: absolute;
        content: "";
        bottom: -10px;
        left: 40px;
        height: 20px;
        width: 20px;
        border: 2px solid #e7fbff;
        transform: rotate(45deg);
        background: $white;
        border-top-color: transparent;
        border-left-color: transparent;
      }

      & i {
        position: absolute;
        top: -30px;
        left: 30px;
        height: 60px;
        width: 60px;
        border-radius: 50px;
        background: #98d16e;
        text-align: center;
        line-height: 60px;
        color: $white;
        font-size: 24px;
        box-shadow: 0px 8px 16px 0px rgba(152, 209, 110, 0.2);
      }

      & .title {
        font-size: 20px;
        line-height: 30px;
        padding-bottom: 20px;

        @media #{$md} {
          font-size: 18px;
        }

        & span {
          display: inline;
          color: #06f;
        }
      }

      & p {
        text-align: center;
        @media #{$md} {
          font-size: 14px;
        }
      }
    }
  }

  & .testimonials-2-active {
    & .slick-dots {
      position: absolute;
      left: 50%;
      bottom: -70px;
      transform: translateX(-50%);
      white-space: nowrap;

      & li {
        display: inline-block;

        & button {
          background-color: transparent;
          font-size: 0;
          height: 6px;
          width: 36px;
          margin: 0 5px;
          border: 0;
          background: #e1edff;
          @include transition(0.3s);
        }

        &.slick-active {
          & button {
            background: #2079ff;
            width: 56px;
          }
        }
      }
    }
  }
}
