/*===========================
    19.OUR EVENTS css 
===========================*/

.our-events-area {
  padding-top: 105px;
  padding-bottom: 120px;

  & .tab-btns {
    border-bottom: 2px solid #e8f2ff;
    padding-bottom: 0px;
    text-align: center;

    & ul {
      display: inline-block;

      & li {
        display: inline-block;

        & a {
          background-color: transparent;
          color: #6b93aa;
          font-size: 18px;
          font-weight: 700;
          height: 50px;
          line-height: 50px;
          padding: 0 25px;

          @media #{$xs} {
            font-size: 12px;
            padding: 0 8px;
          }

          @media #{$sm} {
            font-size: 16px;
            padding: 0 20px;
          }

          & i {
            color: #30e578;
            margin-right: 5px;
          }

          &.active {
            background-color: transparent;
            color: #30bcff;
            position: relative;

            & i {
              color: #30bcff !important;
            }

            &::before {
              position: absolute;
              position: absolute;
              content: "";
              left: 0;
              bottom: -2px;
              height: 2px;
              width: 100%;
              background: #30bcff;
            }
          }
        }

        &:nth-child(2) {
          & a {
            & i {
              color: #f16001;
            }
          }
        }

        &:nth-child(3) {
          & a {
            & i {
              color: #0066ff;
            }
          }
        }
      }
    }
  }

  & .our-events-items {
    border-bottom: 1px solid #d4e7ff;
    padding-bottom: 40px;
    padding-top: 40px;

    & .our-events-thumb {
      position: relative;

      & img {
        width: 100%;
      }

      & .events-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: -webkit-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        background-image: -moz-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        background-image: -ms-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        background-image: -o-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );

        & .events-overlay-content {
          & .title {
            font-size: 60px;
            color: $white;
            font-weight: 700;
            line-height: 60px;

            @media #{$md} {
              font-size: 40px;
              line-height: 30px;
            }
          }

          & span {
            font-weight: 700;
            font-size: 18px;
            color: $white;
          }
        }
      }
    }

    & .our-events-content {
      @media #{$lg} {
        margin-left: 0;
      }

      @media #{$md} {
        margin-left: 0;
      }

      @media #{$xs} {
        margin-left: 0;
        margin-top: 20px;
      }

      & ul {
        & li {
          display: inline-block;
          font-size: 14px;
          font-weight: 700;
          color: #ff7575;
          margin-right: 26px;

          @media #{$xs} {
            font-size: 13px;
            margin-right: 18px;
          }

          @media #{$sm} {
            font-size: 14px;
            margin-right: 18px;
          }

          & i {
            padding-right: 5px;
          }

          &:last-child {
            color: #ffa24f;
          }
        }
      }

      & .title {
        font-size: 40px;
        font-weight: 700;
        padding-top: 5px;
        padding-bottom: 12px;

        @media #{$lg} {
          font-size: 34px;
        }

        @media #{$md} {
          font-size: 28px;
        }

        @media #{$xs} {
          font-size: 22px;
        }

        @media #{$sm} {
          font-size: 36px;
        }
      }

      & p {
        font-size: 14px;
        color: #6b93aa;
        padding-right: 30px;

        @media #{$lg} {
          padding-right: 0;
        }

        @media #{$md} {
          padding-right: 0;
        }

        @media #{$xs} {
          padding-right: 0;
        }
      }
    }

    & .events-btn {
      text-align: right;

      @media #{$xs} {
        text-align: left;
        margin-top: 20px;
      }

      & a {
        height: 50px;
        text-align: center;
        padding: 0 45px;
        border-radius: 30px;
        color: #ff9595;
        font-size: 14px;
        font-weight: 700;
        border: 2px solid #ff9595;
        line-height: 46px;
        @include transition(0.3s);

        @media #{$md} {
          padding: 0 20px;
        }

        &:hover {
          background: #ff9595;
          color: $white;
        }
      }
    }

    &.item {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  & .single-events {
    & .events-thumb {
      position: relative;
      overflow: hidden;

      & img {
        width: 100%;
        @include transition(0.3s);
      }

      & .events-overlay {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 100px;
        width: 100px;
        background-image: -webkit-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        background-image: -moz-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        background-image: -ms-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        background-image: -o-linear-gradient(
          0deg,
          rgba(36, 0, 255, 0.71) 0%,
          rgba(0, 144, 255, 0.7) 100%
        );
        padding-top: 22px;

        & .title {
          color: $white;
          font-size: 50px;
          line-height: 30px;
        }

        & span {
          font-size: 14px;
          font-weight: 700;
          color: $white;
          padding-top: 10px;
        }
      }
    }

    & .events-content {
      padding-top: 33px;

      & ul {
        & li {
          display: inline-block;
          font-size: 14px;
          color: #ff7575;
          font-weight: 700;
          margin-right: 26px;

          @media #{$lg} {
            margin-right: 14px;
          }

          @media #{$xs} {
            margin-right: 14px;
            font-size: 13px;
          }

          @media #{$sm} {
            margin-right: 26px;
            font-size: 13px;
          }

          & i {
            padding-right: 5px;
          }

          &:last-child {
            color: #ffa24f;
          }
        }
      }

      & .title {
        font-size: 30px;
        padding-top: 14px;

        @media #{$lg} {
          font-size: 22px;
        }

        @media #{$md} {
          font-size: 26px;
        }

        @media #{$xs} {
          font-size: 22px;
        }

        @media #{$sm} {
          font-size: 26px;
        }
      }

      & p {
        font-size: 14px;
        padding-top: 15px;
      }

      & a {
        height: 50px;
        line-height: 45px;
        color: #ff9595;
        border: 2px solid #ff9595;
        border-radius: 30px;
        padding: 0 45px;
        font-weight: 700;
        font-size: 14px;
        margin-top: 25px;
        @include transition(0.3s);

        &:hover {
          background: #ff9595;
          border-color: #ff9595;
          color: $white;
        }
      }
    }
    &:hover {
      & .events-thumb {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.event-title-area {
  position: relative;
  padding-top: 115px;
  padding-bottom: 120px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(
      0deg,
      rgba(36, 0, 255, 0.8) 0%,
      rgba(0, 144, 255, 0.8) 100%
    );
    background-image: -moz-linear-gradient(
      0deg,
      rgba(36, 0, 255, 0.8) 0%,
      rgba(0, 144, 255, 0.8) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgba(36, 0, 255, 0.8) 0%,
      rgba(0, 144, 255, 0.8) 100%
    );
    background-image: -o-linear-gradient(
      0deg,
      rgba(36, 0, 255, 0.8) 0%,
      rgba(0, 144, 255, 0.8) 100%
    );
  }

  & .event-title-content {
    & > span {
      color: $white;
      font-size: 18px;
      font-weight: 700;

      & i {
        padding-right: 5px;
      }
    }

    & .title {
      font-size: 80px;
      color: $white;
      padding-top: 3px;
      padding-bottom: 47px;

      @media #{$lg} {
        font-size: 64px;
      }

      @media #{$md} {
        font-size: 56px;
      }

      @media #{$xs} {
        font-size: 38px;
      }

      @media #{$sm} {
        font-size: 44px;
      }
    }

    & ul {
      & li {
        display: inline-block;
        height: 150px;
        width: 150px;
        border: 10px solid #ff9148;
        border-radius: 50%;
        padding-top: 30px;
        margin: 0 18px;

        @media #{$md} {
          height: 130px;
          width: 130px;
          padding-top: 24px;
        }

        @media #{$xs} {
          margin-bottom: 30px;
        }

        & span {
          font-weight: 700;
          font-size: 50px;
          color: $white;
          line-height: 40px;
        }

        & p {
          font-size: 14px;
          font-weight: 700;
          color: $white;
          text-transform: uppercase;
        }

        &:nth-child(2) {
          border-color: #ff6b83;
        }

        &:nth-child(3) {
          border-color: #50f9ff;
        }

        &:nth-child(4) {
          border-color: #fffc00;
        }
      }
    }
  }
}

.speakers-area {
  & .single-speakers {
    & .speakers-thumb {
      position: relative;

      & img {
        width: 100%;
      }

      & a {
        position: absolute;
        height: 60px;
        width: 60px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
        background: #50f9ff;
        color: $heading-color;
        border: 5px solid $white;
      }
    }

    & .speakers-content {
      & span {
        font-size: 14px;
        font-weight: 700;
        color: #f16001;
        text-transform: uppercase;
        letter-spacing: 3px;
        padding-top: 33px;
      }

      & .title {
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 23px;
      }
    }
  }
}
