/*===========================
    9.COUNTER css 
===========================*/

.counter-area {
  position: relative;
  padding-top: 150px;
  padding-bottom: 117px;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 3;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    background-image: -o-linear-gradient(
      0deg,
      rgb(36, 0, 255) 0%,
      rgb(0, 144, 255) 100%
    );
    opacity: 0.8;
    z-index: -2;
  }

  & .section-title {
    & .title {
      color: $white;
      position: relative;
      padding-bottom: 55px;

      @media #{$md} {
        padding: 0 90px;
      }

      @media #{$sm} {
        padding: 0 65px 55px;
      }

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        top: -30px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-bottom: 18px solid $white;
        border-left: 18px solid transparent;
      }
    }
  }

  & .counter-item {
    position: relative;

    @media #{$md} {
      padding-top: 70px;
    }

    @media #{$xs} {
      padding-top: 70px;
    }

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      top: 10px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-bottom: 18px solid #68f6ff;
      border-left: 18px solid transparent;

      @media #{$md} {
        top: 40px;
      }

      @media #{$xs} {
        top: 40px;
      }
    }

    & .title {
      font-size: 70px;
      font-weight: 700;
      color: $white;

      @media #{$xs} {
        font-size: 46px;
      }
    }

    & > span {
      color: $white;
      font-size: 16px;
      font-weight: 700;
    }

    &.item-2 {
      &::before {
        border-bottom: 18px solid #ff68ba;
      }
    }

    &.item-3 {
      &::before {
        border-bottom: 18px solid #f6ff68;
      }
    }

    &.item-4 {
      &::before {
        border-bottom: 18px solid #ca7eff;
      }
    }
  }

  & .counter-dot {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    & img {
      width: 90%;
    }
  }

  &.counter-area-2 {
    padding-top: 148px;

    &::before {
      background-color: rgba(0, 20, 71, 0.8);
      background-image: none;
      opacity: 0.9;
    }

    & .section-title-2 {
      & .title {
        padding-bottom: 7px;

        &::before {
          border-bottom: 18px solid #0066ff;
        }
      }

      & p {
        color: $white;
        opacity: 0.7;
        padding-bottom: 74px;
      }
    }

    & .counter-item {
      & > span {
        opacity: 0.7;
      }
    }

    &.counter-contact {
      &::before {
        background-image: -moz-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        background-image: -webkit-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        background-image: -ms-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        background-image: -o-linear-gradient(
          0deg,
          rgb(36, 0, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        opacity: 0.702;
      }
      & .section-title {
        & .title {
          &::before {
            border-bottom: 18px solid #fff;
          }
        }
      }
    }
  }
}

.about-counter-area {
  background: #002d62;

  & .about-counter-content {
    & .title {
      font-size: 50px;
      line-height: 60px;
      color: $white;
      padding-bottom: 15px;

      @media #{$lg} {
        font-size: 42px;
        line-height: 52px;
      }

      @media #{$xs} {
        font-size: 36px;
        line-height: 46px;
      }
    }

    & span {
      font-size: 28px;
      line-height: 40px;
      font-weight: 400;
      color: $white;
      padding-bottom: 17px;

      @media #{$lg} {
        font-size: 22px;
        line-height: 32px;
      }

      @media #{$xs} {
        font-size: 20px;
        line-height: 30px;
      }

      @media #{$sm} {
        font-size: 26px;
        line-height: 36px;
      }
    }

    & p {
      font-size: 16px;
      color: $white;
      line-height: 28px;
      padding-bottom: 30px;
    }
  }

  & .about-counter-item {
    & .counter-item {
      @media #{$xs} {
        margin-top: 30px;
      }

      & .title {
        font-size: 50px;
        color: $white;
        line-height: 60px;

        @media #{$lg} {
          font-size: 42px;
        }

        @media #{$md} {
          font-size: 42px;
        }

        @media #{$xs} {
          font-size: 42px;
        }
      }

      & > span {
        color: $white;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  & .counter-thumb {
    position: relative;

    @media #{$md} {
      margin-top: 40px;
    }

    @media #{$xs} {
      margin-top: 40px;
      margin-left: 0;
    }

    & img {
      width: 100%;
    }

    & a {
      font-size: 30px;
      color: #06f;
      height: 120px;
      width: 120px;
      text-align: center;
      line-height: 135px;
      border: 0;
      background: $white;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.counter-3-area {
  background: #001a3f;
  padding-top: 120px;
  padding-bottom: 120px;
  & .section-title {
    & > span {
      width: 0;
      height: 0;
      border-top: 18px solid #24ffcd;
      border-right: 18px solid transparent;
    }

    & .title {
      color: $white;
      padding-top: 10px;
      padding-bottom: 33px;
    }
  }
  & .single-counter {
    border: 16px solid #0c2b56;
    & i {
      color: #24ffcd;
      font-size: 30px;
      display: block;
      padding-top: 45px;
      padding-bottom: 10px;
    }
    & span {
      font-size: 16px;
      color: $white;
      font-weight: 700;
      font-family: $montserrat;
      & span {
        font-size: 50px;
      }
    }
    & p {
      color: #a2b4cc;
      font-size: 16px;
      text-transform: capitalize;
      padding-bottom: 40px;
      margin-top: -10px;
    }
    &.item-2 {
      & i {
        color: #ffab74;
      }
    }
    &.item-3 {
      & i {
        color: #00fcff;
      }
    }
    &.item-4 {
      & i {
        color: #ffc924;
      }
    }
  }
}
